import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import 'react-toastify/dist/ReactToastify.css';

// components
import Page from '../components/Page';
import Users from '../components/privilegecardLists/Users';
import Payments from '../components/privilegecardLists/Payments';

export default function PrivilegeCard() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Privilege Cards">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Privilege Card
            </Typography>
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Users" value="1" />
                    <Tab label="Success" value="2" />
                    <Tab label="Failed" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Users />
                </TabPanel>
                <TabPanel value="2">
                  <Payments status={'SUCCESS'} />
                </TabPanel>
                <TabPanel value="3">
                  <Payments status={'FAILED'} />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
