import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from './Iconify';
import EnablePrivilegeCard from './popups/EnablePrivilegeCard';
import UserDelete from './popups/UserDelete';

export default function UserDetails({ userData, getUser, investmentFCompleted, getTotalInvestment }) {
  const { id } = useParams();
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const accType = accountData?.accType;
  const [addressChange, setAddressChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [states, setStates] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delteEnabled, setDeleteEnabled] = useState(false);
  const [open2, setOpen2] = useState(false);
  const navigate = useNavigate();

  const mobileRegExp = '^[56789][0-9]{9}$';
  const pinCodeRegExp = '^[1-9]{1}[0-9]{5}$';
  const userSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required').min(3),
    phone: Yup.string().matches(mobileRegExp, 'Phone number is not valid').required('Phone number is required'),
    email: Yup.string().email('Email must be a valid email address'),
    fatherName: Yup.string().required("Father's name is required").min(3),
    address: Yup.string().required('Address is required').min(5, 'Address is too short!'),
    pinCode: Yup.string().matches(pinCodeRegExp, 'Pin Code is not valid').required('Pin Code is required'),
    state: Yup.string().required('State is required').min(3, 'State is too short!'),
    country: Yup.string().required('Country is required').min(3, 'Country is too short!'),
    dob: Yup.string().required('Date of Birth is required'),
    registrationDate: Yup.string().required('Date of Registration is required'),
  });

  const formik = useFormik({
    initialValues: {
      id,
      fullname: userData?.fullname?.toUpperCase(),
      phone: userData?.phone,
      email: userData?.email,
      dob: userData?.dob,
      registrationDate: userData?.registrationDate ? moment(userData?.registrationDate).format('YYYY-MM-DD') : '',
      remark: userData?.remark,
      fatherName: userData?.fatherName?.toUpperCase(),
      address: userData?.address?.streetAddress,
      pinCode: userData?.address?.pinCode,
      state: userData?.address?.state,
      country: 'INDIA',
      nomineeName: userData?.nomineeDetails?.nomineeName,
      relation: userData?.nomineeDetails?.relation,
      comments: userData?.comments,
    },
    validationSchema: userSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/user/update`, values);
        console.log(res.data);
        toast(res.data.message);
        getUser();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        resetForm();
      }
    },
  });

  const getAllStates = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/state/admin`);
      console.log('states', res);
      setStates(res.data.data.states);
    } catch (error) {
      console.log(error);
    }
  };

  const getDeleteEnabledStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/config/delete-enabeld`);
      console.log('deleteEnabled', res);
      setDeleteEnabled(res.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePopUp = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = (status, message) => {
    setOpen2(false);
    if (status) {
      toast.success(message);
      navigate('/dashboard/user');
    } else {
      toast.error(message);
    }
  };
  const handlePrivilegeSuccess = () => {
    setOpen(false);
    toast.success('Privilege card enabled successfully');
    getUser();
    getTotalInvestment();
  };

  const handlePrivilegeError = (message) => {
    toast.error(message);
  };

  useEffect(() => {
    getAllStates();
    getDeleteEnabledStatus();
  }, []);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  return (
    <Box sx={{ boxShadow: '#25377A 0px 1px 4px', borderRadius: '10px', padding: 1 }}>
      {/* dialog start */}
      <EnablePrivilegeCard
        id={id}
        open={open}
        handleClose={handleClose}
        handlePrivilegeSuccess={handlePrivilegeSuccess}
        handlePrivilegeError={handlePrivilegeError}
      />
      {/* dialog end */}

      <Stack direction="row" justifyContent="space-between" alignItems={'center'} mb={1}>
        <Typography variant="h6" paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }} paddingTop={2} paddingBottom={1}>
          Personal Details
        </Typography>
        <Stack direction="row" alignItems={'center'} mb={1}>
          {delteEnabled && accType === 'SUPER_ADMIN' && (
            <Button
              startIcon={<Iconify icon="material-symbols:delete" />}
              size="small"
              variant="contained"
              color="error"
              onClick={() => setOpen2(true)}
            >
              Delete
            </Button>
          )}
          {open2 && <UserDelete handleClose={handleClose1} />}
          {userData.addressFilled && !userData.privilegeCardEnabled && investmentFCompleted === 9 && (
            <Box paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }}>
              <Button size="small" variant="contained" value={userData?.isBlocked} onClick={handlePopUp}>
                Enable Privilege Card
              </Button>
            </Box>
          )}
        </Stack>
      </Stack>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Fullname"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                fullWidth
                defaultValue={userData?.fullname?.toUpperCase()}
                {...getFieldProps('fullname')}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={touched.fullname && errors.fullname}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="email"
                label="Email"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                defaultValue={userData?.email}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ overflow: 'hidden' }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Phone"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                fullWidth
                defaultValue={userData?.phone}
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                  }
                }}
                onFocus={(e) => {
                  e.target.addEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
                onBlur={(e) => {
                  e.target.removeEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
              />  
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Father's Name"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                defaultValue={userData?.fatherName}
                {...getFieldProps('fatherName')}
                error={Boolean(touched.fatherName && errors.fatherName)}
                helperText={touched.fatherName && errors.fatherName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Date Of Birth"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                defaultValue={userData?.dob}
                {...getFieldProps('dob')}
                error={Boolean(touched.dob && errors.dob)}
                helperText={touched.dob && errors.dob}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Date Of Registration"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                {...getFieldProps('registrationDate')}
                error={Boolean(touched.registrationDate && errors.registrationDate)}
                helperText={touched.registrationDate && errors.registrationDate}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Address"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                defaultValue={userData?.address?.streetAddress}
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Pin Code"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                fullWidth
                defaultValue={userData?.address?.pinCode}
                {...getFieldProps('pinCode')}
                error={Boolean(touched.pinCode && errors.pinCode)}
                helperText={touched.pinCode && errors.pinCode}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                  }
                }}
                onFocus={(e) => {
                  e.target.addEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
                onBlur={(e) => {
                  e.target.removeEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="State"
                fullWidth
                defaultValue={userData?.address?.state}
                {...getFieldProps('state')}
                error={Boolean(touched.state && errors.state)}
                helperText={touched.state && errors.state}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                id="asynchronous-demo"
                defaultValue={userData?.address?.state}
                fullWidth
                open={open1}
                onOpen={() => {
                  setOpen1(true);
                }}
                onClose={() => {
                  setOpen1(false);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={states}
                loading={loading}
                onChange={(_, value) => formik.setFieldValue('state', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    label="State"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Country"
                fullWidth
                disabled
                defaultValue={userData?.address?.country}
                {...getFieldProps('country')}
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Comments"
                multiline
                fullWidth
                defaultValue={userData?.address?.comments}
                {...getFieldProps('comments')}
                error={Boolean(touched.comments && errors.comments)}
                helperText={touched.comments && errors.comments}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="h6">Nominee Details</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Full Name"
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                defaultValue={userData?.nomineeDetails?.nomineeName}
                {...getFieldProps('nomineeName')}
                error={Boolean(touched.nomineeName && errors.nomineeName)}
                helperText={touched.nomineeName && errors.nomineeName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                select
                label="Relation"
                inputProps={{
                  autoComplete: 'new-password',
                }}
                fullWidth
                value={userData?.nomineeDetails?.relation}
                {...getFieldProps('relation')}
                error={Boolean(touched.relation && errors.relation)}
                helperText={touched.relation && errors.relation}
              >
                <MenuItem value="FATHER">FATHER</MenuItem>
                <MenuItem value="MOTHER">MOTHER</MenuItem>
                <MenuItem value="SPOUSE">SPOUSE</MenuItem>
                <MenuItem value="BROTHER">BROTHER</MenuItem>
                <MenuItem value="SISTER">SISTER</MenuItem>
                <MenuItem value="SON">SON</MenuItem>
                <MenuItem value="DAUGHTER">DAUGHTER</MenuItem>
              </TextField>
            </Grid>

            {userData?.isBlocked && (
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Reason to block"
                  fullWidth
                  defaultValue={userData?.remark}
                  {...getFieldProps('remark')}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
              </Grid>
            )}
            <Grid item xs={12} mb={1}>
              <Stack direction="row" style={{ width: '100%' }} justifyContent="flex-end">
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  Update
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
