import {
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Stack,
  Typography,
  Box,
  Card,
  Tooltip,
  Zoom,
  Container,
  Divider,
  Paper,
  Button,
  IconButton,
} from '@mui/material';

import axios from 'axios';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';

import Logo from '../components/Logo';
import { fNumber } from '../utils/formatNumber';
import EditPayment from '../components/EditPayment';

export default function ViewPayment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const componentRef = useRef();
  const instalmentId = searchParams.get('instalment');
  const userId = searchParams.get('user');
  const [paymentData, setPaymentData] = useState({});
  const [instalment, setInstalment] = useState({});
  const [clubInfo, setClubInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const getPayment = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/`, {
        params: { invoiceNumber: id, instalmentId, userId },
      });
      console.log('Response', res);
      setPaymentData(res.data.data.payment);
      setInstalment(res.data.data.instalment);
      setClubInfo(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPayment();
  }, []);

  return (
    <div>
      <Container maxWidth="xl">
        <Card>
          <Stack padding={2} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Payment</Typography>
            <Tooltip TransitionComponent={Zoom} title={'Close'}>
              <Iconify
                icon="codicon:chrome-close"
                onClick={() => navigate(-1)}
                cursor="pointer"
                style={{ fontSize: '24px', color: '#09ADBD', cursor: 'pointer' }}
              />
            </Tooltip>
          </Stack>
          <hr style={{ background: '#D3DCFF', border: 0, height: '1px' }} />
          <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={2}>
            <>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItem: 'center',
                    height: '50vh',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Box
                  sx={{
                    boxShadow: '#6E8AEE 0px 1px 4px',
                    borderRadius: '10px',
                    padding: 1,
                  }}
                >
                  {!editMode && (
                    <Box>
                      <Grid>
                        <Stack
                          marginBottom={0.5}
                          direction="row"
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Basic Info</Typography>
                          <IconButton onClick={() => setEditMode(!editMode)}>
                            <Iconify icon="ant-design:edit-filled" style={{ fontSize: '24px', color: '#09ADBD' }} />
                          </IconButton>
                        </Stack>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Link to={`/dashboard/payment/${paymentData?._id}`}>
                                <Iconify
                                  icon="teenyicons:invoice-solid"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              </Link>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ width: '100%' }}
                            >
                              <Stack direction="column" sx={{ padding: 1 }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Invoice Number</Typography>
                                <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.invoiceNumber}</Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Iconify
                                icon="clarity:date-solid"
                                style={{ height: '30px', width: '30px', color: 'white' }}
                              />
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Transaction Date</Typography>
                              <Stack direction="row" spacing={2} alignItems="end">
                                <Typography style={{ wordBreak: 'break-all' }}>
                                  {moment(paymentData?.transactionDate || paymentData?.createdAt).format('DD-MM-YYYY')}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Iconify
                                icon="clarity:date-solid"
                                style={{ height: '30px', width: '30px', color: 'white' }}
                              />
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                Acknowledgement Date
                              </Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>
                                {moment(paymentData.acknowledgementDate || paymentData.createdAt).format('DD/MM/YYYY')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Link
                                style={{ textDecoration: 'none', color: 'inherit' }}
                                to={`/dashboard/user/${paymentData.userId}?goto=${paymentData.instalmentId}&&status${paymentData.status}`}
                              >
                                <Iconify
                                  icon="emojione-monotone:id-button"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              </Link>
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Instalment ID</Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>{paymentData.instalmentId}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              {' '}
                              <Link to={`/dashboard/user/${paymentData?.userId}`}>
                                <Iconify icon="wpf:name" style={{ height: '30px', width: '30px', color: 'white' }} />{' '}
                              </Link>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              sx={{ width: '100%' }}
                            >
                              <Stack direction="column" sx={{ padding: 1 }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>User ID</Typography>
                                <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.userId}</Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Iconify
                                icon="majesticons:rupee-circle"
                                style={{ height: '30px', width: '30px', color: 'white' }}
                              />
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Amount</Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.amount}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Iconify
                                icon="material-symbols:payments"
                                style={{ height: '30px', width: '30px', color: 'white' }}
                              />
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Method</Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.paymentMethod}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              <Iconify
                                icon="material-symbols:payments"
                                style={{ height: '30px', width: '30px', color: 'white' }}
                              />
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Type</Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.paymentType}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        {paymentData.transactionReference && (
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Stack
                              alignItems="center"
                              spacing={1}
                              direction="row"
                              sx={{
                                height: '100%',
                                background: 'white',
                                borderRadius: '5px',
                                border: '1px solid #C4D0FD',
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                                padding={2}
                              >
                                <Iconify
                                  icon="material-symbols:payments"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              </Stack>
                              <Stack direction="column" sx={{ padding: 1 }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Txn Reference</Typography>
                                <Typography style={{ wordBreak: 'break-all' }}>
                                  {paymentData?.transactionReference}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Stack
                            alignItems="center"
                            spacing={1}
                            direction="row"
                            sx={{
                              height: '100%',
                              background: 'white',
                              borderRadius: '5px',
                              border: '1px solid #C4D0FD',
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                              padding={2}
                            >
                              {paymentData.status === 'SUCCESS' ? (
                                <Iconify
                                  icon="line-md:circle-to-confirm-circle-transition"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              ) : (
                                <Iconify
                                  icon="line-md:close-circle"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              )}
                            </Stack>
                            <Stack direction="column" sx={{ padding: 1 }}>
                              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Status</Typography>
                              <Typography style={{ wordBreak: 'break-all' }}>
                                {paymentData.status?.replaceAll('_', ' ')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>

                        {paymentData?.comment && (
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Stack
                              alignItems="center"
                              spacing={1}
                              direction="row"
                              sx={{
                                height: '100%',
                                background: 'white',
                                borderRadius: '5px',
                                border: '1px solid #C4D0FD',
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                                padding={2}
                              >
                                <Iconify
                                  icon="mdi:comment-text-outline"
                                  style={{ height: '30px', width: '30px', color: 'white' }}
                                />
                              </Stack>
                              <Stack direction="column" sx={{ padding: 1 }}>
                                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Comment</Typography>
                                <Typography style={{ wordBreak: 'break-all' }}>{paymentData?.comment}</Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                  {editMode && (
                    <EditPayment setEditMode={setEditMode} paymentData={paymentData} getPayment={getPayment} />
                  )}
                </Box>
              )}
            </>
          </Box>
          <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingBottom={2}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 700, borderRadius: '5px', marginBottom: '10px' }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ background: '#25377A' }}>
                      <TableCell sx={{ color: 'white' }}>Date</TableCell>
                      <TableCell sx={{ color: 'white' }}>Amount</TableCell>
                      <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                      <TableCell sx={{ color: 'white' }}>Payment Date</TableCell>
                      <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ background: '#E3E9FF' }}>
                      <TableCell>{moment(instalment?.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableCell>
                      <TableCell>{`₹${fNumber(instalment.amount)}`}</TableCell>
                      <TableCell>{instalment?.status?.replaceAll('_', ' ')}</TableCell>
                      <TableCell>
                        {instalment.paymentDate ? moment(instalment?.paymentDate).format('DD-MM-YYYY') : 'NA'}
                      </TableCell>
                      <TableCell>{instalment?.invoiceNumber || 'NA'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>

        {/* invoice  */}
        {paymentData?.status === 'SUCCESS' && (
          <Stack>
            <Box>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" endIcon={<LocalPrintshopIcon />} sx={{ m: 1, float: 'right' }}>
                    Print
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Box>
            <Card ref={componentRef}>
              <Paper elevation={5} sx={{ padding: '40px 0px 10px 70px' }}>
                <Stack sx={{ alignItems: 'center', justifyContent: 'center' }} gap="10px" mb={2}>
                  <Logo sx={{ width: 120, height: 60 }} />
                  <Typography variant="h6">INVOICE DETAILS</Typography>
                </Stack>
                <Box mb={1}>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" textAlign={'initial'} color="primary">
                      Date :
                    </Typography>
                    <Typography variant="body1" textAlign={'initial'} fontWeight="bold" color="primary">
                      {moment(paymentData?.transactionDate || paymentData?.createdAt).format('DD-MM-YYYY')}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" textAlign={'initial'} color="primary">
                      Receipt Number :
                    </Typography>
                    <Typography variant="body1" textAlign={'initial'} fontWeight="bold" color="primary">
                      {paymentData?.invoiceNumber}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="body1" textAlign={'initial'} color="primary">
                      Txn Reference :
                    </Typography>
                    <Typography variant="body1" textAlign={'initial'} fontWeight="bold" color="primary">
                      {paymentData?.transactionReference}
                    </Typography>
                  </Stack>
                </Box>
              </Paper>
              <Box padding="30px 70px 30px 70px">
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Payment For
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {/* {paymentData.paymentFor?.replaceAll('_', ' ')} */}
                    {'INSTALMENT'}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Transaction Type
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {paymentData.paymentMethod}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Payment Type
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {paymentData?.paymentType}
                  </Typography>
                </Stack>{' '}
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Invester file number
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {clubInfo?.clubId}
                  </Typography>
                </Stack>{' '}
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Investment plan
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {clubInfo?.plan?.replaceAll('_', ' ')}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="body1" flex={2} textAlign="initial">
                    Acknowledgment date
                  </Typography>
                  :
                  <Typography variant="body1" flex={1} textAlign="end">
                    {moment(paymentData?.acknowledgementDate || paymentData?.createdAt).format('DD-MM-YYYY')}
                  </Typography>
                </Stack>
                <Divider color="black" />
              </Box>

              <Box padding="0px 70px 40px 70px">
                <Stack direction={'row'} justifyContent="space-between" mb={2}>
                  <Typography variant="h6" flex={2} textAlign="initial" fontWeight={'bold'}>
                    Transaction Amount
                  </Typography>
                  :
                  <Typography variant="h6" flex={1} textAlign="end" fontWeight={'bold'}>
                    {`₹${fNumber(paymentData?.amount)}`}
                  </Typography>
                </Stack>
              </Box>
              <Box padding="0px 70px 40px 70px">
                <Stack spacing={1}>
                  <Typography variant="h6">DECLARATION</Typography>
                  <Typography variant="body1">
                    This invoice shows that <b>{` Rs. ${fNumber(paymentData?.amount)} `}</b>
                    has been contributed by
                    <b>{` ${paymentData?.fullname.toUpperCase()} `}</b> to
                    {` ${clubInfo?.plan?.replaceAll('_', ' ')}.`}
                  </Typography>
                </Stack>
              </Box>
            </Card>
          </Stack>
        )}
      </Container>
    </div>
  );
}
