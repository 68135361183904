import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const UserDelete = (props) => {
  const { id } = useParams();
  const updateStatus = () => {
    let deletePassword = '';
    deletePassword = document.getElementById('deletePassword').value;
    console.log(deletePassword);
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/delete`, {
        userId: id,
        deletePassword,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(false, err.response.data.message ? err.response.data.message : 'Something went wrong');
        
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete the user ?</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="deletePassword"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDelete;
