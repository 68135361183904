import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Stack,
  Typography,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import * as Yup from 'yup';
// import makeStyles from '@mui/styles';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
// form
import './style.css';
import React, { useEffect, useState } from 'react';
import { fNumber } from '../../utils/formatNumber';
import Iconify from '../Iconify';

export default function InstalmentEditTableRFC({ instalments, investmentData, getInvestment, setEditMode }) {
  const [updatedInstalments, setUpdatedInstalments] = useState(instalments);
  const contributionAmount = Number(investmentData?.contributionAmount);
  const [amountError, setAmountError] = useState(false);
  const [updatedContributionAmount, setUpdatedContributionAmount] = useState(contributionAmount);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modeError, setModeError] = useState(false);
  const [advancedEdit, setAdvancedEdit] = useState(false);

  const handleAdvancedEditToggle = (event, newAdvancedEdit) => {
    if (newAdvancedEdit !== null) {
      setAdvancedEdit(newAdvancedEdit);
    }
  };

  const handleChangeDate = (e, id) => {
    const date = e.target.value;
    const temp = updatedInstalments.map((item) => {
      if (item._id === id) {
        return { ...item, date };
      }
      return item;
    });
    setUpdatedInstalments(temp);
  };

  const handleChangeAmount = (e, id) => {
    const amount = e.target.value;
    if (amount < 0) return;
    if (amount % 500 !== 0) {
      setModeError(true);
    } else {
      setModeError(false);
    }
    const temp = updatedInstalments.map((item) => {
      if (item._id === id) {
        return { ...item, amount };
      }
      return item;
    });
    setUpdatedInstalments(temp);
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/investment/${advancedEdit ? 'rfc-advanced' : 'rfc'}`,
        {
          id: investmentData._id,
          payments: updatedInstalments,
        }
      );
      setButtonLoading(false);
      if (!res.data.isError) {
        toast.success('Instalments updated successfully');
        setEditMode(false);
        getInvestment();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
      setButtonLoading(false);
    }
  };

  const handleAddNewRow = () => {
    if (updatedInstalments.length >= 12) {
      toast.error('Maximum 12 instalments allowed');
      return;
    }
    setUpdatedInstalments([
      ...updatedInstalments,
      {
        _id: Date.now(),
        date: moment().format('YYYY-MM-DD'),
        amount: 0,
        status: 'PENDING',
        isDue: false,
        isNew: true,
      },
    ]);
  };

  useEffect(() => {
    const count = updatedInstalments.reduce((prev, current) => prev + Number(current.amount), 0);
    setUpdatedContributionAmount(count);
    console.log(updatedInstalments);
  }, [updatedInstalments]);

  useEffect(() => {
    const targetContributionAmount = contributionAmount;
    if (updatedContributionAmount !== targetContributionAmount) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }, [updatedContributionAmount, advancedEdit, contributionAmount]);

  useEffect(() => {
    if (!advancedEdit) {
      setUpdatedInstalments(instalments);
    }
  }, [advancedEdit]);

  const trueValue = true;
  const falseValue = false;

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ marginBottom: '10px' }}>
        <ToggleButtonGroup
          color="primary"
          value={advancedEdit}
          exclusive
          onChange={handleAdvancedEditToggle}
          aria-label="advanced edit mode"
        >
          <ToggleButton value={falseValue} aria-label="simple edit">
            Simple Edit
          </ToggleButton>
          <ToggleButton value={trueValue} aria-label="advanced edit">
            Advanced Edit
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <TableContainer sx={{ minWidth: 700, borderRadius: '5px', marginBottom: '10px' }}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow sx={{ background: '#25377A' }}>
              <TableCell sx={{ maxWidth: '50px', color: 'white' }}> No</TableCell>
              <TableCell sx={{ color: 'white' }}>Date</TableCell>
              <TableCell sx={{ color: 'white' }}>Amount</TableCell>
              <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updatedInstalments?.map((item, key) => (
              <TableRow id={`id_${item._id}`} key={key} sx={{ background: '#E3E9FF' }}>
                <TableCell sx={{ maxWidth: '50px', color: 'black' }}>{key + 1}</TableCell>
                <TableCell sx={{ color: 'black' }}>
                  <TextField
                    id="date"
                    size="small"
                    type="date"
                    disabled={item?.status === 'COMPLETED'}
                    defaultValue={moment(item?.date).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handleChangeDate(e, item._id);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ color: 'black' }}>
                  <TextField
                    id="amount"
                    size="small"
                    type="number"
                    disabled={item?.status === 'COMPLETED'}
                    onWheel={handleWheel}
                    defaultValue={item?.amount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      handleChangeAmount(e, item._id);
                    }}
                    error={item?.amount % 500 !== 0}
                    helperText={item?.amount % 500 !== 0 ? 'Amount must be a multiple of 500' : ''}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                      }
                    }}
                    onFocus={(e) => {
                      e.target.addEventListener('wheel', (event) => {
                        event.preventDefault();
                      });
                    }}
                    onBlur={(e) => {
                      e.target.removeEventListener('wheel', (event) => {
                        event.preventDefault();
                      });
                    }}
                  />
                </TableCell>
                <TableCell className={item.isDue && 'paymentStatus'} sx={{ color: 'black', width: '250px' }}>
                  {item?.status}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ background: '#E3E9FF' }}>
              <TableCell align="center" colSpan={2} sx={{ maxWidth: '50px', color: 'black', fontWeight: 'bold' }}>
                Contribution Amount
              </TableCell>
              <TableCell>
                <TextField
                  id="contributionAmount"
                  type="number"
                  value={updatedContributionAmount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  onFocus={(e) => {
                    e.target.addEventListener('wheel', (event) => {
                      event.preventDefault();
                    });
                  }}
                  onBlur={(e) => {
                    e.target.removeEventListener('wheel', (event) => {
                      event.preventDefault();
                    });
                  }}
                />
              </TableCell>
              <TableCell sx={{ width: '250px' }}>
                {amountError ? (
                  <Typography sx={{ color: 'red' }}>{`Contribution Amount must be ${contributionAmount}`}</Typography>
                ) : (
                  <Typography sx={{ color: 'green' }}>
                    <Iconify style={{ fontSize: '22px' }} icon="line-md:confirm-circle-twotone" />
                  </Typography>
                )}
              </TableCell>
            </TableRow>{' '}
          </TableBody>
        </Table>
        <Stack direction="row" my={1} spacing={2} justifyContent="flex-end" alignItems="center" sx={{ width: '100%' }}>
          {advancedEdit && (
            <LoadingButton onClick={handleAddNewRow} variant="contained">
              Add Row
            </LoadingButton>
          )}
          <LoadingButton onClick={handleSave} disabled={amountError || modeError} variant="contained">
            Update
          </LoadingButton>
        </Stack>
      </TableContainer>
    </>
  );
}
