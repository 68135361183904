import { createContext } from 'react';

const NotificationContext = createContext({
  newUserCount: 0,
  newTicketCount: 0,
  setNewUserCount: () => {},
  setNewTicketCount: () => {},
});

export default NotificationContext;
