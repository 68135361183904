import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// form
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root ': {
    borderRadius: 2,
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
  },
});

const CssButton = styled(LoadingButton)({
  '&.MuiButtonBase-root': {
    borderRadius: '3px',
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
    backgroundColor: '#09ADBD',
    '&:hover': {
      backgroundColor: '#03646e',
      boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    },
  },
});

export default function ForgotPassForm({ setEmailSent, emailSent,setForgotPass }) {
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await uninterceptedAxiosInstance.post(
          `${process.env.REACT_APP_API_URL}/auth/admin/reset-password`,
          values
        );
        if (!res.data.isError) {
          setEmailSent(true);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error.message);
        toast.error('Error sending password reset email');
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      {!emailSent ? (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} mb={4}>
              <CssTextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>
            <CssButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </CssButton>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Remember your password?
              </Typography>
              <Link onClick={()=>setForgotPass(false)} variant="subtitle2" sx={{ ml: 1, cursor:"pointer"}}>
                Login
              </Link>
            </Stack>
          </Form>
        </FormikProvider>
      ) : (
        <div>
          <Typography variant="h3" sx={{ color: '#182B6D', fontFamily: 'Roboto' }}>
            Check your email
          </Typography>
          <Typography sx={{ color: '#07ADBB', fontFamily: 'Roboto' }}>
            We have sent you an email with a link to reset your password
          </Typography>
        </div>
      )}
    </>
  );
}
