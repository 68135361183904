import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from '../Iconify';

export default function Pan({ kycData, getKyc }) {
  const { id } = useParams();
  const [panEdit, setPanEdit] = useState(Boolean(!kycData?.panDetails));
  const [open, setOpen] = useState(false);
  const [panStatus, setpanStatus] = useState('');
  const [reasonToReject, setReasonTOReject] = useState([]);
  const [rejectInput, setRejectInput] = useState('');

  const panNumberRegExp = '^[A-Z]{5}[0-9]{4}[A-Z]{1}$';
  const FILE_SIZE = 1 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png'];

  const panSchema = Yup.object().shape({
    panNumber: Yup.string().required('PAN number is required').matches(panNumberRegExp, 'PAN number is not valid'),
    pan: Yup.mixed()
      .test(
        'fileSize',
        'File too large, Should be below 512kb',
        (value) => (value && value.size <= FILE_SIZE) || (value === undefined && true)
      )
      // .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const formik = useFormik({
    initialValues: {
      userId: id,
      panNumber: kycData?.panDetails?.panNumber,
      panStatus: kycData?.panStatus,
    },
    validationSchema: panSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/kyc`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        getKyc();
        setPanEdit(false);
        console.log(res.data);
        toast(res.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const changePanStatus = async () => {
    try {
      const reasons = JSON.stringify(reasonToReject);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/kyc`,
        { panStatus, userId: id, reasonsToReject: reasons },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      getKyc();
      setReasonTOReject([]);
      setRejectInput('');
      console.log(res.data);
      setOpen(false);
      toast(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIdPanStatus = (e) => {
    if (e.target.value !== 'PENDING') {
      setpanStatus(e.target.value);
      setOpen(true);
    }
  };
  const updateReasonToReject = (e) => {
    setReasonTOReject((current) => [...current, rejectInput]);
    setRejectInput('');
  };

  const handleClose = () => {
    setOpen(false);
    setReasonTOReject([]);
    setRejectInput('');
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      updateReasonToReject();
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      {/* dialog start */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'ID Proof Status Change?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" marginBottom={2}>
            Are you sure you want to change the PAN status?
          </DialogContentText>
          {panStatus === 'REJECTED' && (
            <>
              {reasonToReject?.map((item, key) => (
                <Grid key={key} item xs={12}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    justifyContent="space-between"
                    direction="row"
                    paddingX={2}
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" sx={{ padding: 1 }}>
                      <Typography>{key + 1}.</Typography>&nbsp;
                      <Typography style={{ wordBreak: 'break-all', cursor: 'pointer' }}>{item}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
              <Stack direction="column" spacing={2}>
                <TextField
                  size="small"
                  fullWidth
                  type="text"
                  onKeyDown={(e) => handleKeypress(e)}
                  component="div"
                  label="Reason to reject"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={updateReasonToReject}
                        sx={{ cursor: 'pointer', visibility: !rejectInput.length ? 'hidden' : 'inherit' }}
                      >
                        <Iconify icon="carbon:add-alt" />
                      </InputAdornment>
                    ),
                  }}
                  value={rejectInput}
                  onInput={(e) => setRejectInput(e.target.value)}
                />
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={changePanStatus} disabled={!reasonToReject.length && panStatus === 'REJECTED'} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog end */}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              boxShadow: '#6E8AEE 0px 1px 4px',
              borderRadius: '10px',
              padding: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>PAN Details</Typography>
                  {!panEdit && (
                    <Iconify
                      onClick={() => setPanEdit((prev) => !prev)}
                      sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
                      icon="ant-design:edit-filled"
                    />
                  )}
                  {panEdit && (
                    <Iconify
                      onClick={() => setPanEdit((prev) => !prev)}
                      sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
                      icon="carbon:view-filled"
                    />
                  )}
                </Stack>
              </Grid>
              {panEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="PAN Number"
                    fullWidth
                    onChange={() => alert('hi')}
                    defaultValue={kycData?.panDetails?.panNumber.toUpperCase()}
                    {...getFieldProps('panNumber')}
                    error={Boolean(touched.panNumber && errors.panNumber)}
                    helperText={touched.panNumber && errors.panNumber}
                  />
                </Grid>
              )}
              {panEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required="true"
                    type="file"
                    label="PAN card"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/jpeg, image/jpg, image/png' }}
                    name="pan"
                    onChange={(event) => formik.setFieldValue('pan', event.target.files[0])}
                    error={Boolean(touched.pan && errors.pan)}
                    helperText={touched.pan && errors.pan}
                  />
                  {/* {Boolean(touched.pan && errors.pan) && (
                     <Typography color="#750409" paddingLeft="10px" variant="caption">
                       Choose a jpg file 
                     </Typography>
                   )} */}
                </Grid>
              )}
              {panEdit && (
                <Grid item xs>
                  <Stack direction={'row'} justifyContent="flex-end" sx={{ width: '100%' }}>
                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Stack>
                </Grid>
              )}
              {!panEdit && kycData.panStatus === 'REJECTED' && (
                <Grid item xs={12}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="icon-park-solid:doc-detail"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1, width: '100%' }}>
                      <Accordion variant="outlined" sx={{ padding: 0 }}>
                        <AccordionSummary sx={{ paddingY: 0 }} expandIcon={<ExpandMoreIcon />}>
                          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Reasons To Reject</Typography>
                        </AccordionSummary>
                        <AccordionDetails x={{ paddingY: 0 }}>
                          <Stack direction={'column'} spacing={1}>
                            {kycData?.panReasonsToReject?.map((item, key) => (
                              <Stack direction={'row'}>
                                <Typography>{key + 1} </Typography>. &nbsp;<Typography>{item} </Typography>{' '}
                              </Stack>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              {!panEdit && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="icon-park-solid:doc-detail"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>PAN Status</Typography>
                      <TextField
                        select
                        fullwidth
                        size="small"
                        sx={{ height: '35px' }}
                        onChange={handleIdPanStatus}
                        value={kycData?.panStatus}
                      >
                        <MenuItem value="PENDING">PENDING</MenuItem>
                        <MenuItem value="IN_PROGRESS">IN PROGRESS</MenuItem>
                        <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              {!panEdit && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="fluent:document-page-number-24-filled"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>PAN Number</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>{kycData?.panDetails?.panNumber}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              )}

              {!panEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      borderRadius: '5px',
                      paddingLeft: 1,
                      border: '1px solid #C4D0FD',
                      height: '100%',
                    }}
                  >
                    <Stack direction="column" sx={{ height: '100%', padding: 1 }} justifyContent="center">
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Document</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        {kycData?.panDetails?.file?.originalName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ height: '100%' }} padding={2}>
                      <Iconify
                        onClick={() => window.open(kycData?.panDetails?.file.fileURL, '_blank')}
                        icon="carbon:task-view"
                        style={{ height: '30px', width: '30px', color: '#09ADBD', cursor: 'pointer' }}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
