import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Box, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment';
import Iconify from './Iconify';

export default function EditPrivilegePayment({ paymentData, getPayment, setEditMode }) {
  const paymentSchema = Yup.object().shape({
    paymentType: Yup.string().required('Payment Type is required'),
    transactionReference: Yup.string(),
    comment: Yup.string(),
    transactionDate: Yup.date().required('Transaction Date is required'),
    acknowledgementDate: Yup.date().required('Acknowledgement Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      invoiceNumber: paymentData.invoiceNumber,
      paymentType: paymentData.paymentType,
      transactionReference: paymentData.transactionReference,
      comment: paymentData.comment,
      transactionDate: moment(paymentData.transactionDate).format('YYYY-MM-DD'),
      acknowledgementDate: moment(paymentData.acknowledgementDate).format('YYYY-MM-DD'),
    },
    validationSchema: paymentSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/payment/privilege`, values);
        toast(res.data.message);
        setEditMode(false);
        getPayment();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleCancel = () => {
    setEditMode(false);
    resetForm();
  };

  return (
    <Box>
      <Grid>
        <Stack marginBottom={0.5} direction="row" justifyContent={'space-between'} alignItems={'center'}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Edit</Typography>
          <IconButton onClick={() => handleCancel()}>
            <Iconify icon="mdi:close-circle-outline" style={{ fontSize: '24px', color: '#09ADBD' }} />
          </IconButton>
        </Stack>
      </Grid>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                select
                label="Payment Type"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('paymentType')}
                error={Boolean(touched.paymentType && errors.paymentType)}
                helperText={touched.paymentType && errors.paymentType}
              >
                <MenuItem value="UPI">UPI</MenuItem>
                <MenuItem value="NEFT">NEFT</MenuItem>
                <MenuItem value="RTGS">RTGS</MenuItem>
                <MenuItem value="IMPS">IMPS</MenuItem>
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                <MenuItem value="DD">DD</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Transaction Date"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('transactionDate')}
                error={Boolean(touched.transactionDate && errors.transactionDate)}
                helperText={touched.transactionDate && errors.transactionDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Acknowledgement Date"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('acknowledgementDate')}
                error={Boolean(touched.acknowledgementDate && errors.acknowledgementDate)}
                helperText={touched.acknowledgementDate && errors.acknowledgementDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                type="text"
                label="Transaction Reference"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('transactionReference')}
                error={Boolean(touched.transactionReference && errors.transactionReference)}
                helperText={touched.transactionReference && errors.transactionReference}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Comment"
                fullWidth
                placeholder="Minutes"
                sx={{ width: '100%' }}
                {...getFieldProps('comment')}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                <LoadingButton variant="outlined" onClick={handleCancel}>
                  Cancel
                </LoadingButton>
                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
