import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  MenuItem,
  TableCell,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';

export default function AddFoundersClub({ getInvestment, userData }) {
  const { id } = useParams();
  const [plan, setPlan] = useState('');
  const [instalments, setInstalments] = useState([]);
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [contributionAmountError, setContributionAmountError] = useState(false);
  const [startDate, setStartDate] = useState('2017-01-01');
  const [endDate, setEndDate] = useState('2025-01-01');

  const regExp = '^[0-9]*[05]*00$';
  const investmentSchema = Yup.object().shape({
    paymentMethod: Yup.string().required('Payment Method is required'),
    contributionAmount: Yup.string().required('Contribution amount is required'),
    paymentMode: Yup.string().required('Payment mode is required'),
    // date: Yup.string().required("Date is required"),
    instalments: Yup.number().when('paymentMode', {
      is: 'INSTALMENT',
      then: Yup.number().integer('Instalment must be an integer').required('Instalment is required').max(12).min(2),
    }),
  });

  const formik = useFormik({
    initialValues: {
      userId: id,
      paymentMethod: '',
      contributionAmount: userData.privilegeCardEnabled ? 10500 : 12500,
      paymentMode: '',
      instalments: '',
      // date: ""
    },
    validationSchema: investmentSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/investment/rfc`, {
          ...values,
          plan: 'RILL_FOUNDERS_CLUB',
        });
        console.log(res.data);
        getInvestment();
        toast(res.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const handleTableReset = () => {
    setInstalments([]);
    setStartDate('2017-01-01');
    setEndDate('2025-01-01');
  };

  const handleChangeAmount = (e) => {
    console.log(e.target.value % 500);
    if (!(e.target.value % 500 === 0) && e.target.value !== 0) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
    setAmount(e.target.value);
  };
  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  const addAmount = (e) => {
    const index = instalments.length;
    const temp = {
      index,
      amount,
      date,
    };
    if (index) {
      setInstalments((prev) => [...prev, temp]);
    } else {
      setInstalments([temp]);
      setStartDate(temp.date);
      setEndDate(moment(temp.date).add(12, 'months').format('YYYY-MM-DD'));
    }
    setAmount('');
    setDate('');
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  // useEffect(() => {
  //   if (values.contributionAmount % 500 !== 0) {
  //     setContributionAmountError(true);
  //   } else if (values.contributionAmount % 500 === 0 && values.contributionAmount !== 0) {
  //     setContributionAmountError(false);
  //   }
  // }, [values.contributionAmount]);

  useEffect(() => {
    handleTableReset();
  }, [values.instalments]);

  useEffect(() => {
    setInstalments([]);
    if (values.paymentMode === 'ONE_TIME') {
      formik.setFieldValue('instalments', 1);
      setAmount(values.contributionAmount);
    } else {
      formik.setFieldValue('instalments', 2);
      setAmount('');
    }
    setDate('');
    setInstalments([]);
  }, [values.paymentMode]);

  useEffect(() => {
    let temp = 0;
    instalments.forEach((item) => {
      const intAmount = Number(item.amount);
      temp += intAmount;
    });
    setTotalAmount(temp);
  }, [instalments]);

  useEffect(() => {
    formik.setFieldValue('payments', instalments);
  }, [instalments]);

  return (
    <Box
      sx={{
        boxShadow: '#6E8AEE 0px 1px 4px',
        borderRadius: '10px',
        paddingBottom: 3,
        paddingTop: 1,
        paddingX: 1,
      }}
    >
      <Stack sx={{ marginBottom: 2, width: '100%' }} justifyContent="flex-end" direction="row">
        <Button
          sx={{ color: `${userData?.privilegeCardEnabled ? 'green' : 'red'}`, pointerEvents: 'none', width: '150px' }}
          variant="outlined"
          endIcon={
            userData?.privilegeCardEnabled ? (
              <Iconify icon="charm:circle-tick" />
            ) : (
              <Iconify icon="charm:circle-cross" />
            )
          }
          size="small"
        >
          Privilege Card
        </Button>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                value="RILL_FOUNDERS_CLUB"
                label="Investment Plan"
                fullWidth
                // {...getFieldProps('plan')}
                error={Boolean(touched.plan && errors.plan)}
                helperText={touched.plan && errors.plan}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                select
                label="Payment Method"
                fullWidth
                value={'OFFLINE'}
                {...getFieldProps('paymentMethod')}
                error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                helperText={touched.paymentMethod && errors.paymentMethod}
              >
                <MenuItem value="OFFLINE">OFFLINE</MenuItem>
                <MenuItem value="ONLINE">ONLINE</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Contribution Amount"
                fullWidth
                disabled
                {...getFieldProps('contributionAmount')}
                error={
                  Boolean(touched.contributionAmount && errors.contributionAmount)
                  // || contributionAmountError
                }
                helperText={
                  touched.contributionAmount && errors.contributionAmount
                  // || (contributionAmountError && 'Must be a multiple of 500')
                }
                onKeyDown={(e) => {
                  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                    e.preventDefault();
                  }
                }}
                onFocus={(e) => {
                  e.target.addEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
                onBlur={(e) => {
                  e.target.removeEventListener('wheel', (event) => {
                    event.preventDefault();
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="text"
                select
                label="Payment Mode"
                fullWidth
                {...getFieldProps('paymentMode')}
                error={Boolean(touched.paymentMode && errors.paymentMode)}
                helperText={touched.paymentMode && errors.paymentMode}
              >
                <MenuItem value="ONE_TIME">ONE TIME</MenuItem>
                <MenuItem value="INSTALMENT">INSTALMENT</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Date "
                fullWidth
                {...getFieldProps('date')}
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
              />
            </Grid> */}
            {values?.paymentMode === 'INSTALMENT' && (
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label="Instalments"
                  select
                  fullWidth
                  {...getFieldProps('instalments')}
                  error={Boolean(touched.instalments && errors.instalments)}
                  helperText={touched.instalments && errors.instalments}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  onFocus={(e) => {
                    e.target.addEventListener('wheel', (event) => {
                      event.preventDefault();
                    });
                  }}
                  onBlur={(e) => {
                    e.target.removeEventListener('wheel', (event) => {
                      event.preventDefault();
                    });
                  }}
                >
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                  <MenuItem value={12}>12</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack justifyContent={'center'} alignItems="center" direction="column">
                {/* <Scrollbar> */}
                {((values?.paymentMode === 'INSTALMENT' && values.instalments > 1) ||
                  (values?.paymentMode === 'ONE_TIME' && values.instalments === 1)) && (
                  <TableContainer sx={{ maxWidth: 700, borderRadius: '5px', marginBottom: '10px' }}>
                    <Stack direction="row" justifyContent="end">
                      <Button onClick={handleTableReset} marginY={2}>
                        Reset
                      </Button>
                    </Stack>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow sx={{ background: '#25377A' }}>
                          <TableCell sx={{ maxWidth: '50px', color: 'white' }}> No</TableCell>
                          <TableCell sx={{ color: 'white' }}>Amount</TableCell>
                          <TableCell sx={{ color: 'white' }}>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!instalments.length &&
                          instalments.map((item, index) => (
                              <>
                                <TableRow key={index} sx={{ background: '#E3E9FF' }}>
                                  <TableCell sx={{ maxWidth: '50px' }}>{index + 1}</TableCell>
                                  <TableCell>
                                    <TextField
                                      InputLabelProps={{ shrink: true }}
                                      type="number"
                                      variant="standard"
                                      required
                                      disabled="true"
                                      {...getFieldProps('index')}
                                      value={item?.amount}
                                      // error={Boolean(touched.contributionAmount && errors.contributionAmount)}
                                      // helperText={touched.contributionAmount && errors.contributionAmount}
                                      onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                          e.preventDefault();
                                        }
                                      }}
                                      onFocus={(e) => {
                                        e.target.addEventListener('wheel', (event) => {
                                          event.preventDefault();
                                        });
                                      }}
                                      onBlur={(e) => {
                                        e.target.removeEventListener('wheel', (event) => {
                                          event.preventDefault();
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type="date"
                                      variant="standard"
                                      disabled="true"
                                      required="true"
                                      InputProps={{ inputProps: { min: startDate, max: endDate } }}
                                      {...getFieldProps(`${index + 100}`)}
                                      value={item?.date}
                                      // inputProps={{
                                      //   min: `${moment().format('YYYY-MM-DD')}`,
                                      // }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}

                        {!(values.instalments <= instalments.length) && (
                          <TableRow sx={{ background: '#E3E9FF' }}>
                            <TableCell sx={{ maxWidth: '50px' }}>{''}</TableCell>
                            <TableCell>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                required
                                onInput={handleChangeAmount}
                                value={amount}
                                disabled={values.paymentMode === 'ONE_TIME'}
                                error={amountError}
                                helperText={amountError && 'Must be a multiple of 500'}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                type="date"
                                variant="standard"
                                InputProps={{ inputProps: { min: startDate, max: endDate } }}
                                required="true"
                                value={date}
                                onChange={handleChangeDate}
                                // inputProps={{
                                //   min: `${moment().format('YYYY-MM-DD')}`,
                                // }}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {/* </Scrollbar> */}

                {!(values.instalments <= instalments.length) && values.paymentMode === 'INSTALMENT' && (
                  <Button
                    onClick={addAmount}
                    disabled={!(date.length && amount.length) || amountError}
                    variant="contained"
                    size="small"
                  >
                    Add
                  </Button>
                )}

                {!(values.instalments <= instalments.length) && values.paymentMode === 'ONE_TIME' && (
                  <Button onClick={addAmount} disabled={!date.length} variant="contained" size="small">
                    Add
                  </Button>
                )}

                {values.paymentMode === 'INSTALMENT' && values.instalments > 1 && (
                  <Stack
                    mt={1}
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems="center"
                    sx={{ width: 250 }}
                  >
                    <Typography>Total Amount</Typography>
                    <TextField
                      sx={{ width: 100 }}
                      size="small"
                      variant="standard"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {totalAmount === values.contributionAmount && (
                              <Iconify style={{ fontSize: '22px' }} icon="line-md:confirm-circle-twotone" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      // error={!(totalAmount === values.contributionAmount)}
                      helperText={
                        !(totalAmount === values.contributionAmount) && `Must be ${values?.contributionAmount}`
                      }
                      value={totalAmount}
                    />
                  </Stack>
                )}
                {values.paymentMode && (
                  <ol style={{ marginTop: '10px' }}>
                    <li>Base Amount: ₹10000</li>
                    <li>Registration: ₹500 </li>
                    {!userData.privilegeCardEnabled && <li>Privilege Card: ₹2000 </li>}
                  </ol>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" style={{ width: '100%' }} justifyContent="flex-end">
                <LoadingButton
                  variant="contained"
                  disabled={!(totalAmount === values.contributionAmount)}
                  type="submit"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
