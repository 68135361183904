// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'KYCs',
    path: '/dashboard/kycs',
    icon: getIcon('icomoon-free:profile'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'Instalments',
    path: '/dashboard/payments',
    icon: getIcon('fluent:payment-16-filled'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'Privilege Cards',
    path: '/dashboard/cards',
    icon: getIcon('fluent:contact-card-ribbon-48-filled'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },

  {
    title: 'customer support',
    path: '/dashboard/support',
    icon: getIcon('healthicons:contact-support-negative'),
    permission: ["SUPER_ADMIN","SUB_ADMIN"],
  },
  {
    title: 'Accounts',
    path: '/dashboard/account',
    icon: getIcon("eos-icons:admin"),
    permission: ["SUPER_ADMIN"],
  },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
