import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Stack, Typography, Box, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import AddInvestment from './AddInvestment';
import BasicInfo from './BasicInfo';
import Instalments from './Instalments';
import AddPartnersClub from './AddPartnersClub';

export default function PartnersClub({ handleInvestmentCompleted, getUser, getTotalInvestment }) {
  const { id } = useParams();
  const [investmentData, setInvestmentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [noInvestment, setNoInvestment] = useState(true);

  const getInvestment = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/investment/partners`, { params: { userId: id } });
      console.log('investment', res);
      if (!res.data.isError) {
        setInvestmentData(res.data.data.investment);
        setIsLoading(false);
        setNoInvestment(false);
        const count = res.data.data.investment.payments.reduce((prev, current) => {
          if (current.status === 'COMPLETED') {
            return prev + 1;
          }
          return prev;
        }, 0);
        switch (true) {
          case count === res.data.data.investment.payments.length:
            handleInvestmentCompleted(3);
            break;
          case count === 0:
            handleInvestmentCompleted(1);
            break;
          case count > 0:
            handleInvestmentCompleted(2);
            break;
          default:
            return;
        }
      } else {
        setIsLoading(false);
        setNoInvestment(true);
        handleInvestmentCompleted(9);
      }
      getUser();
      getTotalInvestment();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvestment();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box sx={{ boxShadow: '#25377A 0px 1px 4px', borderRadius: '10px', padding: 1 }}>
            {!noInvestment && (
              <Grid container spacing={1} paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }} paddingTop={2}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent={'space-between'} alignItems="center">
                    <Typography variant="h6">Partners Club</Typography>
                    <Typography sx={{ color: 'green', fontWeight: 'bold' }}> {investmentData?.clubId}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <BasicInfo investmentData={investmentData} getInvestment={getInvestment} />
                </Grid>
                <Grid item xs={12} mb={1}>
                  <Instalments
                    investmentData={investmentData}
                    instalments={investmentData.payments}
                    getInvestment={getInvestment}
                    plan ={"RPC"}
                  />
                </Grid>
              </Grid>
            )}
            {noInvestment && (
              <Grid container spacing={1} paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }} paddingTop={2}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent={'space-between'} alignItems="center">
                    <Typography variant="h6">Partners Club</Typography>
                    <Typography sx={{ color: 'green', fontWeight: 'bold' }}> {investmentData?.clubId}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <AddPartnersClub getInvestment={getInvestment} />
                </Grid>
              </Grid>
            )}
          </Box>
        </>
      )}
    </>
  );
}
