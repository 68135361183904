import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from '../Iconify';

export default function Aadhaar({ kycData, getKyc }) {
  const { id } = useParams();
  const [aadhaarEdit, setAadhaarEdit] = useState(Boolean(!kycData.aadhaarDetails));
  const [open, setOpen] = useState(false);
  const [aadhaarStatus, setAadhaarStatus] = useState('');
  const [reasonToReject, setReasonTOReject] = useState([]);
  const [rejectInput, setRejectInput] = useState('');

  const FILE_SIZE = 1 * 1024 * 1024;
  // const SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png'];

  const aadhaarNumberRegExp = '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$';
  const aadhaarSchema = Yup.object().shape({
    aadhaarNumber: Yup.string()
      .required('Aadhaar number is required')
      .matches(aadhaarNumberRegExp, 'Aadhaar number is not valid'),
    aadhaar: Yup.mixed()
      .test(
        'fileSize',
        'File too large, Should be below 512kb',
        (value) => (value && value.size <= FILE_SIZE) || (value === undefined && true)
      )
      // .test('fileFormat', 'Unsupported Format', (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const formik = useFormik({
    initialValues: {
      userId: id,
      aadhaarNumber: kycData?.aadhaarDetails?.aadhaarNumber,
      aadhaarStatus: kycData?.aadhaarStatus,
    },
    validationSchema: aadhaarSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/kyc`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        getKyc();
        setAadhaarEdit(false);
        console.log(res.data);
        toast(res.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const changeAadhaarStatus = async () => {
    try {
      const reasons = JSON.stringify(reasonToReject);
      console.log(reasonToReject);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/kyc`,
        { aadhaarStatus, userId: id, reasonsToReject: reasons },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      getKyc();
      setReasonTOReject([]);
      setRejectInput('');
      console.log(res.data);
      setOpen(false);
      toast(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  const updateReasonToReject = (e) => {
    setReasonTOReject((current) => [...current, rejectInput]);
    setRejectInput('');
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      updateReasonToReject();
    }
  };

  const handleAadhaarStatus = (e) => {
    if (e.target.value !== 'PENDING') {
      setAadhaarStatus(e.target.value);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setReasonTOReject([]);
    setRejectInput('');
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      {/* dialog start */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'ID Proof Status Change?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" marginBottom={2}>
            Are you sure you want to change the Aadhaar status?
          </DialogContentText>
          {aadhaarStatus === 'REJECTED' && (
            <>
              {reasonToReject?.map((item, key) => (
                <Grid key={key} item xs={12}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    justifyContent="space-between"
                    direction="row"
                    paddingX={2}
                    sx={{ width: '100%' }}
                  >
                    <Stack direction="row" sx={{ padding: 1 }}>
                      <Typography>{key + 1}.</Typography>&nbsp;
                      <Typography style={{ wordBreak: 'break-all', cursor: 'pointer' }}>{item}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
              <Stack direction="column" spacing={2}>
                <TextField
                  size="small"
                  fullWidth
                  type="text"
                  component="div"
                  label="Reason to reject"
                  onKeyDown={(e) => handleKeypress(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={updateReasonToReject}
                        sx={{ cursor: 'pointer', visibility: !rejectInput.length ? 'hidden' : 'inherit' }}
                      >
                        <Iconify icon="carbon:add-alt" />
                      </InputAdornment>
                    ),
                  }}
                  value={rejectInput}
                  onInput={(e) => setRejectInput(e.target.value)}
                />
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={changeAadhaarStatus}
            disabled={!reasonToReject.length && aadhaarStatus === 'REJECTED'}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialog end */}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              boxShadow: '#6E8AEE 0px 1px 4px',
              borderRadius: '10px',
              padding: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Aadhaar Details</Typography>
                  {!aadhaarEdit && (
                    <Iconify
                      onClick={() => setAadhaarEdit((prev) => !prev)}
                      sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
                      icon="ant-design:edit-filled"
                    />
                  )}
                  {aadhaarEdit && (
                    <Iconify
                      onClick={() => setAadhaarEdit((prev) => !prev)}
                      sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
                      icon="carbon:view-filled"
                    />
                  )}
                </Stack>
              </Grid>
              {aadhaarEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Aadhaar Number"
                    fullWidth
                    defaultValue={kycData?.aadhaarDetails?.aadhaarNumber}
                    {...getFieldProps('aadhaarNumber')}
                    error={Boolean(touched.aadhaarNumber && errors.aadhaarNumber)}
                    helperText={touched.aadhaarNumber && errors.aadhaarNumber}
                  />
                </Grid>
              )}
              {aadhaarEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    required="true"
                    type="file"
                    label="Aadhaar card"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ accept: 'image/jpeg, image/jpg, image/png' }}
                    name="aadhaar"
                    onChange={(event) => formik.setFieldValue('aadhaar', event.target.files[0])}
                    error={Boolean(touched.aadhaar && errors.aadhaar)}
                    helperText={touched.aadhaar && errors.aadhaar}
                  />
                  {/* {Boolean(touched.aadhaar && errors.aadhaar) && (
                    <Typography color="#750409" paddingLeft="10px" variant="caption">
                      Choose a jpg file 
                    </Typography>
                  )} */}
                </Grid>
              )}
              {aadhaarEdit && (
                <Grid item xs>
                  <Stack direction={'row'} justifyContent="flex-end" sx={{ width: '100%' }}>
                    <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Stack>
                </Grid>
              )}
              {!aadhaarEdit && kycData.aadhaarStatus === 'REJECTED' && (
                <Grid item xs={12}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="icon-park-solid:doc-detail"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1, width: '100%' }}>
                      <Accordion variant="outlined" sx={{ padding: 0 }}>
                        <AccordionSummary sx={{ paddingY: 0 }} expandIcon={<ExpandMoreIcon />}>
                          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Reasons To Reject</Typography>
                        </AccordionSummary>
                        <AccordionDetails x={{ paddingY: 0 }}>
                          <Stack direction={'column'} spacing={1}>
                            {kycData?.aadhaarReasonsToReject?.map((item, key) => (
                              <Stack direction={'row'}>
                                <Typography>{key + 1} </Typography>. &nbsp;<Typography>{item} </Typography>{' '}
                              </Stack>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              {!aadhaarEdit && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify
                        icon="icon-park-solid:doc-detail"
                        style={{ height: '30px', width: '30px', color: 'white' }}
                      />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Aadhaar Status</Typography>

                      <TextField
                        select
                        fullwidth
                        size="small"
                        sx={{ height: '35px' }}
                        onChange={handleAadhaarStatus}
                        value={kycData?.aadhaarStatus}
                      >
                        <MenuItem value="PENDING">PENDING</MenuItem>
                        <MenuItem value="IN_PROGRESS">IN PROGRESS</MenuItem>
                        <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                      </TextField>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              {!aadhaarEdit && (
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Stack
                    alignItems="center"
                    spacing={1}
                    direction="row"
                    sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                      padding={2}
                    >
                      <Iconify icon="bxs:id-card" style={{ height: '30px', width: '30px', color: 'white' }} />
                    </Stack>

                    <Stack direction="column" sx={{ padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Aadhaar Number</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        {kycData?.aadhaarDetails?.aadhaarNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              )}

              {!aadhaarEdit && (
                <Grid item xs={12} sm={6} md={4}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      borderRadius: '5px',
                      paddingLeft: 1,
                      border: '1px solid #C4D0FD',
                      height: '100%',
                    }}
                  >
                    <Stack direction="column" justifyContent="center" sx={{ height: '100%', padding: 1 }}>
                      <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Document</Typography>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        {kycData?.aadhaarDetails?.file?.originalName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ height: '100%' }} padding={2}>
                      <Iconify
                        onClick={() => window.open(kycData?.aadhaarDetails?.file.fileURL, '_blank')}
                        icon="carbon:task-view"
                        style={{ height: '30px', width: '30px', color: '#09ADBD', cursor: 'pointer' }}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
