import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NotificationContext from './NotificationContext';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

import { GlobalDebug } from './utils/RemoveConsoles';

// ----------------------------------------------------------------------

export default function App() {
  const [newUserCount, setNewUserCount] = useState(0);
  const [newTicketCount, setNewTicketCount] = useState(0);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin-notification`);
      console.log('response notification', response?.data?.data);
      const { newUser, newTicket } = response?.data?.data;
      setNewUserCount(newUser);
      setNewTicketCount(newTicket);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {

    const interval = setInterval(() => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        fetchNotifications();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') {
      GlobalDebug(false);
    }
  }, []);

  return (
    <NotificationContext.Provider value={{ newUserCount, newTicketCount, setNewUserCount, setNewTicketCount }}>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </ThemeProvider>
    </NotificationContext.Provider>
  );
}
