import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, Stack, Typography, Box, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
// import KycAdd from './KycAdd';
import Pan from './kyc/Pan';
import Aadhaar from './kyc/Aaadhar';
import Bank from './kyc/Bank';
import Passport from './kyc/Passport';

export default function KycDetails({ handlekycCompleted }) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const goto = searchParams.get('goto');
  const gotoSection = searchParams.get('section');
  const [kycData, setKycData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [noKyc, setNoKyc] = useState(true);

  const getKyc = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/kyc/`, { params: { userId: id } });
      console.log('Response', res);
      if (!res.data.isError) {
        setKycData(res.data.data.kyc);
        setIsLoading(false);
        setNoKyc(false);
      } else {
        setIsLoading(false);
        setNoKyc(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (e) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/kyc`,
        { kycStatus: e.target.value, userId: id },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(res.data);
      toast(res.data.message);
      getKyc();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getKyc();
    const section = document.getElementById(`${gotoSection}`);
    if (section) {
      section?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById(`id_${goto}`);
    if (element) {
      element.style.background = 'green';
      const children = element.children;
      const length = children.length;
      for (let i = 0; i < length; i += 1) {
        children[i].style.color = 'white';
      }
      element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [kycData]);
  
  

  useEffect(() => {
    if (kycData.kycStatus === true) {
      handlekycCompleted(2);
    } else if (kycData.kycStatus === false) {
      handlekycCompleted(1);
    }
  }, [kycData.kycStatus]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box sx={{ boxShadow: '#25377A 0px 1px 4px', borderRadius: '10px', padding: 1 }}>
            <Grid container spacing={1} paddingX={{ lg: 2, md: 1.5, sm: 1, xs: 0 }} paddingTop={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={'space-between'} alignItems="center">
                  <Typography variant="h6">KYC</Typography>
                  {!kycData?.kycStatus &&
                    kycData.panStatus === 'COMPLETED' &&
                    kycData.aadhaarStatus === 'COMPLETED' &&
                    kycData.bankStatus === 'COMPLETED' &&
                    ['COMPLETED', 'PENDING'].includes(kycData.passportStatus) && (
                      <FormControl>
                        <Select
                          size="small"
                          value={kycData?.kycStatus}
                          sx={{ height: '35px' }}
                          onChange={handleStatusChange}
                        >
                          <MenuItem value="true">COMPLETED</MenuItem>
                          <MenuItem value="false"> NOT COMPLETED</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                </Stack>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Pan kycData={kycData} getKyc={getKyc} />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Aadhaar kycData={kycData} getKyc={getKyc} />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Bank kycData={kycData} getKyc={getKyc} />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Passport kycData={kycData} getKyc={getKyc} />
              </Grid>
            </Grid>
          </Box>
          {/* {noKyc && <KycAdd />} */}
        </>
      )}
    </>
  );
}
