import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from 'react-toastify';
import Page from '../components/Page';
import Popup from '../components/popups/userStatusChange';
import UserDetails from '../components/UserDetails';
import KycDetails from '../components/KycDetails';
import Iconify from '../components/Iconify';
import InvestmentDetails from '../components/investment/InvestmentDetails';
import FoundersClub from '../components/investment/FoundersClub';
import PartnersClub from '../components/investment/PartnersClub';
import Logo from '../components/Logo';

export default function ViewUser() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [totalInvestment, setTotalInvestment] = useState('');
  const [kycCompleted, setKycCompleted] = useState(0);
  const [investmentFCompleted, setInvestmentFCompleted] = useState(0);
  const [investmentPCompleted, setInvestmentPCompleted] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [privilegeInfo, setPrivilegeInfo] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [privilegeCardNumber, setPrivilegeCardNumber] = useState('');
  const [error, setError] = useState(false);

  const getUser = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/`, { params: { userId: id } });
      console.log('Response', res);
      setUserData(res.data.data.user);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalInvestment = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/total`, { params: { userId: id } });
      console.log('Response', res);
      if (!res.data.isError) {
        setTotalInvestment(res.data.data.totalPayment?.total);
      } else {
        setTotalInvestment(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevilageCard = async () => {
    setDialogOpen(!dialogOpen);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/privilege-card`, {
        params: { userId: id },
      });
      console.log('Response', res);
      if (!res.data.isError) {
        console.log(res);
        setPrivilegeInfo(res.data.data);
        setPrivilegeCardNumber(res.data.data.privilegeCard.privilegeCardNumber);
        setEdit(false);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = () => {
    setOpen(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setOpen(false);
    if (refresh) {
      getUser();
    }
    if (message) {
      toast(message);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
  };

  const handlekycCompleted = (data) => {
    setKycCompleted(data);
  };

  const handleInvestmentFCompleted = (data) => {
    setInvestmentFCompleted(data);
  };
  const handleInvestmentPCompleted = (data) => {
    setInvestmentPCompleted(data);
  };

  const srollToKyc = () => {
    const kycSection = document.getElementById('kyc');
    kycSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };

  const srollToInvestmentF = () => {
    const kycSection = document.getElementById('investmentf');
    kycSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };
  const srollToInvestmentP = () => {
    const kycSection = document.getElementById('investmentp');
    kycSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  };
  useEffect(() => {
    getUser();
    getTotalInvestment();
  }, []);

  const updatePrivilageCardNumber = async () => {
    try {
      if (!(/^[0-9]{5}/.test(privilegeCardNumber))) {
        setError(true);
        console.log('error');
        return;
      }
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/privilege-card/update`, {
        userId: id,
        privilegeCardNumber:`RLPC${privilegeCardNumber}`,
      });
      console.log(res.data);
      if (!res.data.isError) {
        setDialogOpen(false)
        toast(res.data.message);
        setEdit(!edit);
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.message);
    }
  };

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Container maxWidth="xl">
            <Card>
              <Stack paddingTop={2} paddingX={2} direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" justifyContent={'space-between'} alignItems="center" spacing={1}>
                  {(kycCompleted === 1 || kycCompleted === 2) && (
                    <Button
                      sx={{ ...(kycCompleted === 2 ? { color: 'green' } : { color: 'red' }) }}
                      variant="outlined"
                      onClick={srollToKyc}
                      size="small"
                      endIcon={
                        kycCompleted === 1 ? (
                          <Iconify icon="charm:circle-cross" />
                        ) : (
                          <Iconify icon="charm:circle-tick" />
                        )
                      }
                    >
                      KYC
                    </Button>
                  )}
                  {investmentFCompleted !== 0 && (
                    <Button
                      sx={
                        (investmentFCompleted === 1 && { color: 'red' }) ||
                        (investmentFCompleted === 9 && { color: 'red' }) ||
                        (investmentFCompleted === 2 && { color: 'orange' }) ||
                        (investmentFCompleted === 3 && { color: 'green' })
                      }
                      variant="outlined"
                      onClick={srollToInvestmentF}
                      size="small"
                      endIcon={
                        investmentFCompleted === 9 ? (
                          <Iconify icon="charm:circle-cross" />
                        ) : (
                          <Iconify icon="charm:circle-tick" />
                        )
                      }
                    >
                      Founders Club
                    </Button>
                  )}
                  {investmentFCompleted !== 0 && (
                    <Button
                      sx={
                        (investmentPCompleted === 1 && { color: 'red' }) ||
                        (investmentPCompleted === 9 && { color: 'red' }) ||
                        (investmentPCompleted === 2 && { color: 'orange' }) ||
                        (investmentPCompleted === 3 && { color: 'green' })
                      }
                      variant="outlined"
                      onClick={srollToInvestmentP}
                      size="small"
                      endIcon={
                        investmentPCompleted === 9 ? (
                          <Iconify icon="charm:circle-cross" />
                        ) : (
                          <Iconify icon="charm:circle-tick" />
                        )
                      }
                    >
                      Partners Club
                    </Button>
                  )}
                  <Button variant="outlined" size="small">{`TOTAL INVESTMENT: ${totalInvestment}`}</Button>
                </Stack>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems="center">
                  {userData?.privilegeCardEnabled && (
                    <Button
                      sx={{ color: 'green', cursor: 'pointer' }}
                      variant="outlined"
                      endIcon={<Iconify icon="charm:circle-tick" />}
                      onClick={handlePrevilageCard}
                    >
                      Privilege Card
                    </Button>
                  )}
                  <FormControl>
                    <Select
                      size="small"
                      value={userData?.isBlocked}
                      onChange={handleStatusChange}
                      sx={{
                        ...(userData.isBlocked ? { color: '#660000' } : { color: '#006600' }),
                        height: '35px',
                      }}
                    >
                      <MenuItem value="false"> Active</MenuItem>
                      <MenuItem value="true">Blocked</MenuItem>
                    </Select>
                    {open && <Popup item={userData} handleClose={handleClose} />}
                  </FormControl>
                  <Tooltip TransitionComponent={Zoom} title={'Close'}>
                    <Iconify
                      icon="codicon:chrome-close"
                      onClick={() => navigate(-1)}
                      cursor="pointer"
                      style={{ fontSize: '24px', color: '#09ADBD', cursor: 'pointer' }}
                    />
                  </Tooltip>
                </Stack>
              </Stack>
              <Stack paddingBottom={1} paddingX={2} direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems={'center'} spacing={1} justifyContent="space-between">
                  <Typography variant="h6">{userData?.fullname?.toUpperCase()}</Typography>
                </Stack>
              </Stack>
              <hr style={{ background: '#D3DCFF', border: 0, height: '1px' }} />
              <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }} paddingY={2}>
                <Box id="userDetails" marginBottom={2}>
                  {userData && (
                    <UserDetails
                      userData={userData}
                      sx={{ scrollMargin: '100px' }}
                      getUser={getUser}
                      investmentFCompleted={investmentFCompleted}
                      getTotalInvestment={getTotalInvestment}
                    />
                  )}
                </Box>
                <Box id="kyc" sx={{ scrollMargin: '100px' }} marginBottom={2}>
                  <KycDetails userData={userData} handlekycCompleted={handlekycCompleted} />
                </Box>
                <Box id="investmentf" sx={{ scrollMargin: '100px' }} marginBottom={2}>
                  <FoundersClub
                    handleInvestmentCompleted={handleInvestmentFCompleted}
                    userData={userData}
                    getUser={getUser}
                    getTotalInvestment={getTotalInvestment}
                  />
                </Box>
                <Box id="investmentp" sx={{ scrollMargin: '100px' }} marginBottom={2}>
                  <PartnersClub
                    handleInvestmentCompleted={handleInvestmentPCompleted}
                    getUser={getUser}
                    getTotalInvestment={getTotalInvestment}
                  />
                </Box>
              </Box>
            </Card>
          </Container>
          {/* dialog  */}

          <Dialog onClose={handleDialogClose} open={dialogOpen} maxWidth="sm" fullWidth>
            <Box sx={{ position: 'relative' }}>
              <img src={'/static/privilege.png'} alt="privilege card" width={'100%'} />
            </Box>
            <Box sx={{ position: 'absolute', width: '100%' }}>
              <DialogTitle>
                <Stack direction={'row'} justifyContent="space-between" alignItems={'center'} p={{ xs: 0, sm: 2 }}>
                  <Typography variant="h4" fontSize={{ xs: '16px', sm: '24px' }}>
                    PRIVILEGE CARD
                  </Typography>
                  <Logo sx={{ width: { xs: 70, sm: 93 }, height: { xs: 36, sm: 49 } }} />
                </Stack>
              </DialogTitle>
              <Card sx={{ backgroundColor: 'transparent', borderRadius: 'unset', boxShadow: 'none' }}>
                <Stack p={{ xs: 3, sm: 6 }} pt={{ xs: 1, sm: 6 }} spacing={1}>
                  <Typography variant="h6" fontWeight={'bold'}>
                    {privilegeInfo?.userInfo?.fullname.toUpperCase()}
                  </Typography>
                  <Stack direction={'row'} alignItems="center">
                    <Typography  fontWeight={'bold'}>
                    {privilegeInfo?.privilegeCard?.privilegeCardNumber}
                    </Typography>
                    {edit && (
                      <Stack direction="row" spacing={1}>
                        <IconButton aria-label="Done" size="small">
                          <DoneIcon fontSize="small" onClick={updatePrivilageCardNumber} color="secondary" />
                        </IconButton>
                        <IconButton aria-label="Close" size="small">
                          <CloseIcon
                            fontSize="small"
                            onClick={() => {
                              setEdit(!edit);
                              setPrivilegeCardNumber('');
                            }}
                            color="error"
                          />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Box>
          </Dialog>
          {/* dialgog ends */}
        </div>
      )}
    </Page>
  );
}
