import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                ticketNumber: '',
                userId: '',
                fullname: '',
                phone: '',
                instalmentId: '',
                type: '',
                startDate: '',
                endDate: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" sx={{ minWidth: { lg: '300px', md: '300px', sm: '300px', xl: '300px' } }}>
                    <Stack spacing={1} direction="row">
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="ticketNumber"
                        name="ticketNumber"
                        label="Ticket Number"
                        value={values.ticketNumber}
                        onChange={handleChange}
                      />

                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="userId"
                        name="userId"
                        fullWidth
                        label="User ID"
                        value={values.userId}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack spacing={1} direction="row">
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="fullname"
                        name="fullname"
                        fullWidth
                        label="Fullname"
                        value={values.fullname}
                        onChange={handleChange}
                      />
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="phone"
                        name="phone"
                        fullWidth
                        label="Phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack spacing={1} direction="row">
                      <TextField
                        select
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="type"
                        name="type"
                        fullWidth
                        label="Type"
                        value={values.type}
                        onChange={handleChange}
                      >
                        <MenuItem value="RFC">RFC</MenuItem>
                        <MenuItem value="RPC">RPC</MenuItem>
                        <MenuItem value="PC">PC</MenuItem>
                      </TextField>
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="instalmentId"
                        name="instalmentId"
                        fullWidth
                        label="Instalment ID"
                        value={values.instalmentId}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <TextField
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        style={{ paddingBottom: '10px' }}
                        id="startDate"
                        name="startDate"
                        fullWidth
                        label="Start Date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        size="small"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ paddingBottom: '10px' }}
                        id="endDate"
                        name="endDate"
                        fullWidth
                        label="End Date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
