import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export default function EnablePrivilegeCard({ id, open, handleClose, handlePrivilegeSuccess, handlePrivilegeError }) {
  const statusSchema = Yup.object().shape({
    paymentType: Yup.string().required('Payment Type is required'),
  });

  const formik = useFormik({
    initialValues: {
      paymentType: '',
      transactionReference: '',
      comment: '',
      transactionDate: '',
      acknowledgementDate: '',
    },
    validationSchema: statusSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/privilege-card/enable`, {
          userId: id,
          ...values,
        });
       handlePrivilegeSuccess()
      } catch (error) {
        console.log(error);
        handlePrivilegeError(error.response.data.message)
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{'Payment Status Change?'}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack
                spacing={1}
                sx={{ width: { xs: '100%', sm: '450px' } }}
                marginY={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  fullWidth
                  select
                  label="Payment Type"
                  {...getFieldProps('paymentType')}
                  error={Boolean(touched.paymentType && errors.paymentType)}
                  helperText={touched.paymentType && errors.paymentType}
                >
                  <MenuItem value="UPI">UPI</MenuItem>
                  <MenuItem value="NEFT">NEFT</MenuItem>
                  <MenuItem value="RTGS">RTGS</MenuItem>
                  <MenuItem value="IMPS">IMPS</MenuItem>
                  <MenuItem value="CASH">CASH</MenuItem>
                  <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                  <MenuItem value="DD">DD</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Transaction Reference"
                  {...getFieldProps('transactionReference')}
                  error={Boolean(touched.transactionReference && errors.transactionReference)}
                  helperText={touched.transactionReference && errors.transactionReference}
                />
              </Stack>
              <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                <TextField
                  fullWidth
                  label="Transaction Date"
                  InputLabelProps={{ shrink: true }}
                  type={'date'}
                  {...getFieldProps('transactionDate')}
                  error={Boolean(touched.transactionDate && errors.transactionDate)}
                  helperText={touched.transactionDate && errors.transactionDate}
                />
                <TextField
                  fullWidth
                  type={'date'}
                  InputLabelProps={{ shrink: true }}
                  label="Acknowledgement Date"
                  {...getFieldProps('acknowledgementDate')}
                  error={Boolean(touched.acknowledgementDate && errors.acknowledgementDate)}
                  helperText={touched.acknowledgementDate && errors.acknowledgementDate}
                />
              </Stack>
              <Stack marginY={1} spacing={1} direction="row" justifyContent="center" alignItems="center">
                <TextField
                  fullWidth
                  label="Comment"
                  {...getFieldProps('comment')}
                  error={Boolean(touched.comment && errors.comment)}
                  helperText={touched.comment && errors.comment}
                />
              </Stack>
              <Stack marginY={2} spacing={2} direction="row" justifyContent="end" alignItems="center">
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
