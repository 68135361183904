import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                userId: '',
                fullname: '',
                phone: '',
                email: '',
                paymentMethod: '',
                invoiceNumber: '',
                clubId: '',
                startDate: '',
                endDate: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" sx={{ minWidth: { lg: '400px', md: '400px', sm: '400px', xl: '400px' } }}>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="invoiceNumber"
                        name="invoiceNumber"
                        label="Invoice Number"
                        value={values.invoiceNumber}
                        onChange={handleChange}
                      />
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="clubId"
                        name="clubId"
                        label="IF Number"
                        value={values.clubId}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack spacing={1} direction={{ lg: 'row', xl: 'row', md: 'row', sm: 'row', xs: 'column' }}>
                      {' '}
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        id="userId"
                        name="userId"
                        fullWidth
                        label="User ID"
                        value={values.userId}
                        onChange={handleChange}
                      />
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Stack>
                    <TextField
                      size="small"
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="fullname"
                      name="fullname"
                      label="Fullname"
                      value={values.fullname}
                      onChange={handleChange}
                    />
                    <Stack direction={'row'} spacing={2}>
                      <TextField
                        size="small"
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <TextField
                        size="small"
                        select
                        id="paymentMethod"
                        name="paymentMethod"
                        label="Payment Method"
                        value={values.paymentMethod}
                        fullWidth
                        onChange={handleChange}
                      >
                        <MenuItem value="">NO FILTER</MenuItem>
                        <MenuItem value="ONLINE">ONLINE</MenuItem>
                        <MenuItem value="OFFLINE">OFFLINE</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <TextField
                        size="small"
                        type="date"
                        name="startDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        size="small"
                        name="endDate"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
