import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../Page';
import PendingRfcPayments from './PendingRfcPayments';
import PendingRpcPayments from './PendingRpcPaymens';

export default function Paymentss() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="RFC" value="1" />
            <Tab label="RPC" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PendingRfcPayments />
        </TabPanel>
        <TabPanel value="2">
          <PendingRpcPayments />
        </TabPanel>
      </TabContext>
    </>
  );
}
