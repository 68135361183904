import moment from 'moment';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, useMediaQuery, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  MemberCount,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  MemberPieChart,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:1419px)');
  const [isLoading1, setIsLoading1] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);
  const [loader3, setLoader3] = useState(true);
  const [loader4, setLoader4] = useState(true);
  const [loader5, setLoader5] = useState(true);
  const [loader6, setLoader6] = useState(true);
  const [loader7, setLoader7] = useState(true);
  const [loader8, setLoader8] = useState(true);
  const [loader9, setLoader9] = useState(true);
  const [loader10, setLoader10] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);
  const [isLoading6, setIsLoading6] = useState(true);
  const [isLoading7, setIsLoading7] = useState(true);
  const [isLoading8, setIsLoading8] = useState(true);
  const [isLoading9, setIsLoading9] = useState(true);
  const [isLoading10, setIsLoading10] = useState(true);
  const [totalInvestment, setTotalInvestment] = useState([]);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [privilegeAmountData, setPrivilegeAmountData] = useState([]);
  const [completedInvestment, setCompletedInvestment] = useState([]);
  const [pendingInvestment, setPendingInvestment] = useState([]);
  const [rpcInvestment, setRpcInvestment] = useState([]);
  const [rfcInvestment, setRfcInvestment] = useState([]);
  const [rfcMemberCount, setRfcMemberCount] = useState([]);
  const [rpcMemberCount, setRpcMemberCount] = useState([]);
  const [userCount, setUserCount] = useState({});
  const [userMonthlyCount, setUserMonthlyCount] = useState({});

  const getTotalInvestment = async (options) => {
    try {
      setLoader1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/investment/total`, { params: options });
      console.log('res', res);
      const temp = res.data.data.totalInvestment;
      setLoader1(false);
      setTotalInvestment(temp);
      if (temp.length) {
        setIsLoading1(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCompletedInvestment = async (options) => {
    try {
      setLoader2(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/investment/completed`, {
        params: options,
      });
      console.log('res', res);
      const temp = res.data.data.totalInvestment;
      setLoader2(false);
      setCompletedInvestment(temp);
      if (temp.length) {
        setIsLoading2(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPendingInvestment = async (options) => {
    try {
      setLoader3(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/investment/pending`, { params: options });
      console.log('res', res);
      const temp = res.data.data.pendingInvestment;
      setLoader3(false);
      setPendingInvestment(temp);
      if (temp.length) {
        setIsLoading3(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRpcInvestment = async (options) => {
    try {
      setLoader4(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/investment/rpc`, { params: options });
      console.log('res', res);
      const temp = res.data.data.rpcData;
      setLoader4(false);
      setRpcInvestment(temp);
      if (temp.length) {
        setIsLoading4(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRfcInvestment = async (options) => {
    try {
      setLoader5(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/investment/rfc`, { params: options });
      console.log('res', res);
      const temp = res.data.data.rfcData;
      setLoader5(false);
      setRfcInvestment(temp);
      if (temp.length) {
        setIsLoading5(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPrivilegeData = async (options) => {
    try {
      setLoader6(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/privilege`, {
        params: options,
      });
      console.log('Privilege', res);
      const temp = res.data.data.chartData;
      setPrivilegeData(temp);
      setLoader6(false);
      if (temp.length) {
        setIsLoading6(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPrivilegeAmountData = async (options) => {
    try {
      setLoader10(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/privilege`, {
        params: options,
      });
      console.log('Privilege', res);
      const temp = res.data.data.chartData;
      const temp1 = temp.filter((item) => item._id !== 'RFC_PC');
      const temp2 = temp1.map((item) => ({
        ...item,
        total: item.total * 2000,
      }));
      setPrivilegeAmountData(temp2);
      console.log('temp2', temp2);
      setLoader10(false);
      if (temp.length) {
        setIsLoading10(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRfcUserData = async (options) => {
    try {
      setLoader7(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/user/rfc`, { params: options });
      console.log('rfc', res);
      const temp = res.data.data.rfcMembers;
      setLoader7(false);
      setRfcMemberCount(temp);
      if (temp.length) {
        setIsLoading7(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getRpcUserData = async (options) => {
    try {
      setLoader8(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/user/rpc`, { params: options });
      console.log('rpc', res);
      const temp = res.data.data.rpcMembers;
      setLoader8(false);
      setRpcMemberCount(temp);
      if (temp.length) {
        setIsLoading8(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/user`);
      console.log('userCounts', res);
      const temp = res.data.data.userCount;
      setUserCount(temp);
      setIsLoading9(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserMonthlyCount = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/user/monthly/count`, {
        params: options,
      });
      console.log('monthly counts', res);
      const temp = res.data.data.clubMembersData;
      setUserMonthlyCount(temp);
      setIsLoading10(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTotalInvestment();
    getCompletedInvestment();
    getPendingInvestment();
    getRpcInvestment();
    getRfcInvestment();
    // getPrivilegeInvestment();
    getRfcUserData();
    getRpcUserData();
    getPrivilegeData();
    getUserCounts();
    getUserMonthlyCount({
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(5, 'month').format('YYYY-MM-DD'),
    });
    getPrivilegeAmountData();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={matches ? 12 : 8}>
            <AppWebsiteVisits
              title="NEW MEMBERS"
              // subheader="(+43%) than last year"
              getNewData={getUserMonthlyCount}
              // graphloader={loader1}
              chartLabels={userMonthlyCount?.chartLabels}
              chartData={[
                {
                  name: 'RFC',
                  type: 'line',
                  fill: 'solid',
                  data: userMonthlyCount?.rfcdata,
                  color: '#E2AF00',
                },
                {
                  name: 'RPC',
                  type: 'line',
                  fill: 'solid',
                  data: userMonthlyCount?.rpcdata,
                  color: '#00719F',
                },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}
            />
          </Grid>

          {!isLoading9 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <MemberCount title="Traffic by Site" list={userCount} />
            </Grid>
          )}
          {!isLoading1 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="COMMITTED INVESTMENTS"
                chartData={totalInvestment}
                getNewData={getTotalInvestment}
                graphloader={loader1}
                chartColors={['#E2AF00', '#00719F']}
              />
            </Grid>
          )}

          {!isLoading2 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="COMPLETED INVESTMENTS"
                chartData={completedInvestment}
                getNewData={getCompletedInvestment}
                graphloader={loader2}
                chartColors={['#E2AF00', '#00719F']}
              />
            </Grid>
          )}
          {!isLoading3 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="PENDING INVESTMENTS"
                hideStartDate={'hide'}
                chartData={pendingInvestment}
                getNewData={getPendingInvestment}
                graphloader={loader3}
                chartColors={['#E2AF00', '#00719F']}
              />
            </Grid>
          )}

          {!isLoading4 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="RPC INVESTMENTS"
                hideStartDate={'hide'}
                chartData={rpcInvestment}
                graphloader={loader4}
                getNewData={getRpcInvestment}
                chartColors={['#8A9A5B', '#CC5500']}
              />
            </Grid>
          )}

          {!isLoading5 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="RFC INVESTMENTS"
                hideStartDate={'hide'}
                chartData={rfcInvestment}
                graphloader={loader5}
                getNewData={getRfcInvestment}
                chartColors={['#8A9A5B', '#CC5500']}
              />
            </Grid>
          )}

          {!isLoading10 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="PC INVESTMENTS"
                chartData={privilegeAmountData}
                getNewData={getPrivilegeAmountData}
                graphloader={loader10}
                chartColors={['#E2AF00', '#00719F']}
              />
            </Grid>
          )}

          {/* {!isLoading6 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <AppCurrentVisits
                title="PRIVILEGE CARD INVESTMENTS"
                chartData={privilegeInvestment}
                graphloader={loader6}
                getNewData={getPrivilegeInvestment}
                chartColors={['#E7B040', '#4077E7', theme.palette.chart.green[0], theme.palette.chart.yellow[0]]}
              />
            </Grid>
          )} */}

          {!isLoading8 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <MemberPieChart
                title="RPC MEMBERS"
                chartData={rpcMemberCount}
                graphloader={loader8}
                getNewData={getRpcUserData}
                chartColors={['#64b928', '#f29900']}
              />
            </Grid>
          )}

          {!isLoading7 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <MemberPieChart
                title="RFC MEMBERS"
                chartData={rfcMemberCount}
                graphloader={loader7}
                getNewData={getRfcUserData}
                chartColors={['#64b928', '#f29900']}
              />
            </Grid>
          )}

          {!isLoading3 && (
            <Grid item xs={12} md={6} lg={matches ? 6 : 4}>
              <MemberPieChart
                title="PRIVILEGE CARD"
                chartData={privilegeData}
                getNewData={getPrivilegeData}
                graphloader={loader6}
                chartColors={['#E2AF00', '#00719F', '#CC5500']}
              />
            </Grid>
          )}

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Weekly Sales" total={714000} icon={'ant-design:android-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="New Users" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid> */}
          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
