import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/UserFilter';
import Filter1 from '../components/filters/UserInvestmentDate';

import Export from '../components/filters/UserExport';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/userStatusChange';
import NotificationContext from '../NotificationContext';

export default function User() {
  const { setNewUserCount } = useContext(NotificationContext);
  const [userData, setUserData] = useState();
  const [pages, setPages] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [dateFilter, setDateFilter] = useState(false);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const mobileRegExp = '^[56789][0-9]{9}';

  const userSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required').min(4, 'Fullname must be at least 4 charecters'),
    phone: Yup.string()
      .matches(mobileRegExp, 'Phone number is not valid')
      .required('Phone number is required')
      .max(10, 'Phone number is not valid'),
    email: Yup.string().email('Email must be a valid email address'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      comments: '',
    },
    validationSchema: userSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/add`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getUsers(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getUsers(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };
  const closeAddUserModal = (message) => {
    setOpen(false);
    toast(message);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getUsers(temp);
  };

  const updateNotificationCount = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin-notification`, { type: 'NEW_USER' });
      console.log('res', res);
      setNewUserCount(0);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setUserCount(res.data.data.maxRecords);
          setUserData(res.data.data.users);
          updateNotificationCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportUsers = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/user/export`, options);
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setPages(0);
    setOptions(temp);
    getUsers(temp);
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(userCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getUsers(temp);
  };

  const applyFilters1 = (filter) => {
    const temp = { page: 0, size };
    if (filter.investmentDatePlan) {
      temp.investmentDatePlan = filter.investmentDatePlan;
    }
    if (filter.investmentStartDate) {
      temp.investmentStartDate = filter.investmentStartDate;
    }
    if (filter.investmentEndDate) {
      temp.investmentEndDate = filter.investmentEndDate;
    }

    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };

  const handleRefresh = () => {
    const temp = { page: 0, size };
    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.name.length) {
      temp.name = filter.name.trim();
    }
    if (filter.phone.length) {
      temp.phone = filter.phone;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.clubId.length) {
      temp.clubId = filter.clubId;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.plan.length) {
      temp.plan = filter.plan;
    }
    if (filter.completed.length) {
      temp.completed = filter.completed;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.pending) {
      temp.pending = filter.pending;
    }
    if (filter.privilegeCard) {
      temp.privilegeCard = filter.privilegeCard;
    }
    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };
  useEffect(() => {
    getUsers(options);
  }, []);

  useEffect(() => {
    if (options.investmentEndDate || options.investmentStartDate) {
      setDateFilter(true);
    }
  }, [options]);

  return (
    <>
      <Page title="User">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add user Dialog */}
            <Dialog open={open}>
              <DialogTitle>Add User</DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width="500px">
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Fullname"
                              fullWidth
                              value={values.fullname}
                              {...getFieldProps('fullname')}
                              error={Boolean(touched.fullname && errors.fullname)}
                              helperText={touched.fullname && errors.fullname}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <TextField
                              type="text"
                              label="Email"
                              value={values.email}
                              fullWidth
                              {...getFieldProps('email')}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                            />
                            <TextField
                              type="number"
                              label="Phone Number"
                              fullWidth
                              value={values.phone}
                              {...getFieldProps('phone')}
                              error={Boolean(touched.phone && errors.phone)}
                              helperText={touched.phone && errors.phone}
                              onKeyDown={(e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                  e.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                e.target.addEventListener('wheel', (event) => {
                                  event.preventDefault();
                                });
                              }}
                              onBlur={(e) => {
                                e.target.removeEventListener('wheel', (event) => {
                                  event.preventDefault();
                                });
                              }}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Comments"
                              fullWidth
                              multiline
                              value={values.comments}
                              {...getFieldProps('comments')}
                              error={Boolean(touched.comments && errors.comments)}
                              helperText={touched.comments && errors.comments}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddUserModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add User
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add user dialog end */}
            <Container maxWidth="xl">
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={1}>
                <Export />
                <LoadingButton
                  endIcon={<Iconify icon="ph:export-bold" />}
                  variant="outlined"
                  onClick={() => exportUsers(options)}
                  loading={isLoading1}
                  sx={{ height: '35px' }}
                  size="small"
                >
                  Export
                </LoadingButton>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Users
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<Refresh />}
                  onClick={() => handleRefresh()}
                  style={{ maxHeight: '35px' }}
                >
                  Refresh
                </Button>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small" 
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                          e.preventDefault();
                        }
                      }}
                      onFocus={(e) => {
                        e.target.addEventListener('wheel', (event) => {
                          event.preventDefault();
                        });
                      }}
                      onBlur={(e) => {
                        e.target.removeEventListener('wheel', (event) => {
                          event.preventDefault();
                        });
                      }}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                    <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                      Add Users
                    </Button>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <Filter1 applyFilters={applyFilters1} />
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      {/* <UserListHead /> */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>RFC ID</TableCell>
                          <TableCell>RPC ID</TableCell>
                          <TableCell>User ID</TableCell>
                          <TableCell>Name</TableCell>
                          {/* <TableCell>Email</TableCell> */}
                          <TableCell>Phone</TableCell>
                          <TableCell>Member since</TableCell>
                          <TableCell>User status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userData?.map((item, id) => (
                          <TableRow key={id}>
                            <TableCell style={{ width: '50px' }}>{pages * size + (id + 1)}</TableCell>
                            <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                              {item?.rfcId ? (
                                <Stack direction="column" spacing={0.5} width="80px">
                                  <CopyToClipboard text={item.rfcId} onCopy={() => toast.info('RFC ID copied')}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={0.5}
                                      justifyContent="flex-start"
                                    >
                                      <Typography>{item?.rfcId}</Typography>
                                    </Stack>
                                  </CopyToClipboard>
                                </Stack>
                              ) : (
                                <>NA</>
                              )}
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                              {item?.rpcId ? (
                                <Stack direction="column" spacing={0.5} width="80px">
                                  <CopyToClipboard text={item.rpcId} onCopy={() => toast.info('RPC ID copied')}>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={0.5}
                                      justifyContent="flex-start"
                                    >
                                      <Typography>{item?.rpcId}</Typography>
                                    </Stack>
                                  </CopyToClipboard>
                                </Stack>
                              ) : (
                                <>NA</>
                              )}
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('User id copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '18px', width: '18px', color: '#09ADBD' }}
                                    />
                                    <Typography sx={{ color: '#09ADBD' }} variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell
                              style={{
                                minWidth: '130px',
                                maxWidth: '220px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {item?.fullname?.toUpperCase()}
                            </TableCell>
                            {/* <TableCell style={{ minWidth: '130px', wordBreak: 'break-all' }}>{item.email}</TableCell> */}
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>
                              {item.registrationDate ? Moment(item.registrationDate).format('MMMM DD, YYYY') : 'NA'}
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={!item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="true">Active</MenuItem>
                                  <MenuItem value="false">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Link to={`/dashboard/user/${item._id}`}>
                                  <Icon sx={{ color: 'gray' }}>
                                    <RemoveRedEyeIcon />
                                  </Icon>
                                </Link>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onRowsPerPageChange={handleSizeChange}
                  component={'div'}
                  count={userCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
