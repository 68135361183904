import moment from 'moment';
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Refresh } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../filters/Tickets';
import Popup from '../popups/SupportStatusChange';

// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import NotificationContext from '../../NotificationContext';

export default function Tickets({ status }) {
  const { setNewTicketCount } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [ticketsData, setTicketsData] = useState();
  const [pages, setPages] = useState(0);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, status });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [loadingNoteSubmit, setLoadingNoteSubmit] = useState(false);

  const [openNotesDialog, setOpenNotesDialog] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleOpenNotesDialog = (ticket) => {
    setSelectedTicket(ticket);
    setOpenNotesDialog(true);
  };

  const handleCloseNotesDialog = () => {
    setSelectedTicket(null);
    setOpenNotesDialog(false);
  };

  const handleSubmitNotes = async () => {
    try {
      setLoadingNoteSubmit(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/customer-support/admin/notes`, {
        notes: selectedTicket.notes,
        ticketId: selectedTicket._id,
      });
      console.log('res', res);
      if (res.data.isError === false) {
        handleCloseNotesDialog();
        toast(res.data.message);
      }
      getTickets(options);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoadingNoteSubmit(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage, status };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTickets(temp);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getTickets(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${!item.status}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setOptions(temp);
    setPages(0);
    getTickets(temp);
  };

  const updateNotificationCount = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin-notification`, { type: 'NEW_TICKET' });
      console.log('res', res);
      setNewTicketCount(0);
    } catch (error) {
      console.log(error);
    }
  };

  const getTickets = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/customer-support/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTicketsCount(res.data.data.maxRecords);
          setTicketsData(res.data.data.records);
          if (status === 'PENDING') {
            updateNotificationCount();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePopup = (e, item) => {
    e.preventDefault();
    console.log('item', item);
  };

  const goToPage = () => {
    if (input > Math.ceil(ticketsCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTickets(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size, status };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.ticketNumber.length) {
      temp.ticketNumber = filter.ticketNumber;
    }
    if (filter.type.length) {
      temp.type = filter.type;
    }
    if (filter.instalmentId.length) {
      temp.instalmentId = filter.instalmentId;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.phone.length) {
      temp.phone = filter.phone;
    }
    setOptions(temp);
    setPages(0);
    getTickets(temp);
  };
  const handleRefresh = () => {
    const temp = { size, page: 0, status };
    setOptions(temp);
    setPages(0);
    getTickets(temp);
  };
  useEffect(() => {
    getTickets(options);
  }, []);
  return (
    <>
      <Page title="Payments">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Dialog open={openNotesDialog} onClose={handleCloseNotesDialog} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">{selectedTicket?.notes ? 'Edit Notes' : 'Add Notes'}</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="notes"
                  label="Notes"
                  multiline
                  type="text"
                  fullWidth
                  sx={{minWidth:"400px"}}
                  defaultValue={selectedTicket?.notes}
                  onChange={(e) => setSelectedTicket({ ...selectedTicket, notes: e.target.value })}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseNotesDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubmitNotes} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <TextField
                    size="small"
                    style={{ maxWidth: '100px', maxHeight: '35px' }}
                    type="number"
                    component="div"
                    label="Go to"
                    value={input}
                    onInput={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                      }
                    }}
                    onFocus={(e) => {
                      e.target.addEventListener('wheel', (event) => {
                        event.preventDefault();
                      });
                    }}
                    onBlur={(e) => {
                      e.target.removeEventListener('wheel', (event) => {
                        event.preventDefault();
                      });
                    }}
                  />
                  <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                    Go
                  </Button>
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Refresh />}
                    onClick={() => handleRefresh()}
                    style={{ maxHeight: '35px' }}
                  >
                    Refresh
                  </Button>
                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <paymentListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Ticket Number</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Instalment ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Notes</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ticketsData?.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell sx={{ minWidth: '100px' }}>
                            {moment(item.createdAt).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>
                            <Stack direction="column" spacing={0.5} width="90px">
                              <CopyToClipboard
                                text={item.ticketNumber}
                                onCopy={() => toast.info('Ticekt number copied')}
                              >
                                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                  {item?.ticketNumber}
                                </Stack>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer', width: '75px' }}>
                            <Stack direction="row" width="80px">
                              <Tooltip title="View User">
                                <IconButton onClick={() => navigate(`/dashboard/user/${item.userId}`)}>
                                  <Iconify icon="mdi:user" style={{ height: '22px', width: '22px', color: 'green' }} />
                                </IconButton>
                              </Tooltip>

                              <CopyToClipboard text={item.userId} onCopy={() => toast.info('User id copied')}>
                                <Tooltip title="Copy User ID">
                                  <IconButton>
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '22px', width: '22px', color: '#09ADBD' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell
                            sx={{
                              textTransform: 'uppercase',
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {item?.fullname?.toUpperCase()}
                          </TableCell>
                          <TableCell>{item?.phone}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>
                            {item?.metadata && item.metadata.instalmentId && item.type !== 'PC' && (
                              <Stack direction="column" spacing={0.5}>
                                <Link
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                  to={`/dashboard/user/${item.userId}?goto=${item.metadata.instalmentId}`}
                                >
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <Iconify
                                      icon="gg:user-list"
                                      style={{ height: '18px', width: '18px', color: 'green' }}
                                    />
                                    <Typography sx={{ color: 'green' }} variant="p">
                                      View
                                    </Typography>
                                  </Stack>
                                </Link>
                              </Stack>
                            )}
                          </TableCell>
                          <TableCell>
                            {item.status === 'PENDING' ? (
                              <FormControl>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={item.status}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                  size="small"
                                >
                                  <MenuItem value="PENDING">PENDING</MenuItem>
                                  <MenuItem value="CLOSED">CLOSED</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            ) : (
                              <>{item.status}</>
                            )}
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleOpenNotesDialog(item)}>
                              {item.notes ? <EditIcon /> : <AddCircleOutlineIcon />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowsPerPageChange={handleSizeChange}
                component={'div'}
                count={ticketsCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </>
        )}
      </Page>
    </>
  );
}
