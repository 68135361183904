import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import ForgotPassForm from '../sections/auth/ForgotPassword/ForgotPassForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: '#F5FEFF',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#F5FEFF',
  alignItems: 'center',
  boxShadow: 'rgba(48, 74, 169, 0.2) 5px 5px 50px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const [forgotPass, setForgotPass] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  const images = ['/static/DOCTOR.png', '/static/NURSE.png'];

  return (
    <Page title="Login">
      <RootStyle>
        {/*    <HeaderStyle>
          {mdUp&&<Logo />} */}

        {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )} */}
        {/* </HeaderStyle> */}

        {mdUp && (
          <SectionStyle>
            <Logo sx={{ mb: 3, height: '80px', width: '150px' }} />
            {/* randomly set image from images */}
            <Box
              component="img"
              src={images[Math.floor(Math.random() * images.length)]}
              alt="login"
              sx={{
                width: '80%',
                height: '50%',
                objectFit: 'contain',
                borderRadius: 1,
              }}
            />
            <Typography variant="h3" sx={{ mt: 4, mb: 4, color: '#545454', fontFamily: 'Roboto' }}>
              Hi, Welcome Back
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            {!emailSent && (
              <Typography
                gutterBottom
                textAlign="center"
                sx={{ width: '100%', color: '#545454', fontSize: '24px', fontFamily: 'Roboto', fontWeight: 500 }}
              >
                {forgotPass ? 'Forgot Password?' : ' Sign in to RILL'}
              </Typography>
            )}

            {!emailSent && (
              <Typography sx={{ color: '#192B6D', mb: 5, width: '100%' }} textAlign="center">
               {forgotPass ? 'Enter your email address' : 'Enter your details below.'}
              </Typography>
            )}

            {/* <AuthSocial /> */}

            {!forgotPass ? (
              <LoginForm setForgotPass={setForgotPass} />
            ) : (
              <ForgotPassForm setForgotPass={setForgotPass} setEmailSent={setEmailSent} emailSent={emailSent} />
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
