import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Stack,
  Typography,
  FormControl,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import * as Yup from 'yup';
// import makeStyles from '@mui/styles';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
// form
import { useFormik, Form, FormikProvider } from 'formik';
import './style.css';
import React, { useEffect, useState } from 'react';
import InstalmentEditTableRPC from './InstalmentEditTableRPC';
import Scrollbar from '../Scrollbar';
import { fNumber } from '../../utils/formatNumber';
import Iconify from '../Iconify';
import InstalmentEditTableRFC from './InstalmentEditTableRFC';

// const useStyles = makeStyles({
//   root: {
//     width: '535px',
//     height: '61px',
//     flexGrow: '0',
//     margin: '40px 100px 20px 0',
//     padding: '19px 329px 20px 20px',
//     border: 'solid 1px var(--select-border)',
//     backgroundColor: '#ffffff',
//   },
// });

export default function Instalments({ investmentData, instalments, getInvestment, plan }) {
  const [searchParams] = useSearchParams();
  const goto = searchParams.get('goto');
  const gotoSection = searchParams.get('section');
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(Boolean(instalments.length));
  // const [instalments, setInstalments] = useState(investmentData?.payments);
  const [instalmentStatus, setInstalmentStatus] = useState('');
  const [instalmentId, setInstalmentId] = useState('');
  const [editMode, setEditMode] = useState(false);

  // const classes = useStyles();

  const statusSchema = Yup.object().shape({
    paymentType: Yup.string().required('Payment Type is required'),
  });

  const formik = useFormik({
    initialValues: {
      paymentType: '',
      transactionReference: '',
      comment: '',
      transactionDate: '',
      acknowledgementDate: '',
    },
    validationSchema: statusSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/investment`, {
          ...values,
          userId: id,
          instalmentId,
          status: instalmentStatus,
          plan: investmentData?.plan,
        });
        setOpen(false);
        toast(res.data.message);
        getInvestment();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleInstalmentStatus = (e, item) => {
    setInstalmentStatus(e.target.value);
    setInstalmentId(item?._id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const element = document.getElementById(`id_${goto}`);
    if (element && scroll) {
      element.style.background = 'green';
      const children = element.children;
      const length = children.length;
      for (let i = 0; i < length; i += 1) {
        children[i].style.color = 'white';
      }
      element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
    const section = document.getElementById(`${gotoSection}`);
    if (section) {
      section?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [scroll]);

  return (
    <>
      {/* dialog start */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{'Payment Status Change?'}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  spacing={1}
                  sx={{ width: { xs: '100%', sm: '450px' } }}
                  marginY={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    select
                    label="Payment Type"
                    {...getFieldProps('paymentType')}
                    error={Boolean(touched.paymentType && errors.paymentType)}
                    helperText={touched.paymentType && errors.paymentType}
                  >
                    <MenuItem value="UPI">UPI</MenuItem>
                    <MenuItem value="NEFT">NEFT</MenuItem>
                    <MenuItem value="RTGS">RTGS</MenuItem>
                    <MenuItem value="IMPS">IMPS</MenuItem>
                    <MenuItem value="CASH">CASH</MenuItem>
                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                    <MenuItem value="DD">DD</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    label="Transaction Reference"
                    {...getFieldProps('transactionReference')}
                    error={Boolean(touched.transactionReference && errors.transactionReference)}
                    helperText={touched.transactionReference && errors.transactionReference}
                  />
                </Stack>
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                  <TextField
                    fullWidth
                    label="Transaction Date"
                    InputLabelProps={{ shrink: true }}
                    type={'date'}
                    {...getFieldProps('transactionDate')}
                    error={Boolean(touched.transactionDate && errors.transactionDate)}
                    helperText={touched.transactionDate && errors.transactionDate}
                  />
                  <TextField
                    fullWidth
                    type={'date'}
                    InputLabelProps={{ shrink: true }}
                    label="Acknowledgement Date"
                    {...getFieldProps('acknowledgementDate')}
                    error={Boolean(touched.acknowledgementDate && errors.acknowledgementDate)}
                    helperText={touched.acknowledgementDate && errors.acknowledgementDate}
                  />
                </Stack>
                <Stack marginY={1} spacing={1} direction="row" justifyContent="center" alignItems="center">
                  <TextField
                    fullWidth
                    label="Comment"
                    {...getFieldProps('comment')}
                    error={Boolean(touched.comment && errors.comment)}
                    helperText={touched.comment && errors.comment}
                  />
                </Stack>
                <Stack marginY={2} spacing={2} direction="row" justifyContent="end" alignItems="center">
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* dialog end */}
      <Stack direction="row" justifyContent={'space-between'} mt={1} mb={1}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Instalments</Typography>
        {!editMode && (
          <Iconify
            onClick={() => setEditMode((prev) => !prev)}
            sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
            icon="ant-design:edit-filled"
          />
        )}
        {editMode && (
          <Iconify
            onClick={() => setEditMode((prev) => !prev)}
            sx={{ fontSize: '20px', cursor: 'pointer', color: 'primary.main' }}
            icon="carbon:view-filled"
          />
        )}
      </Stack>
      <Scrollbar>
        {!editMode && (
          <TableContainer sx={{ minWidth: 700, borderRadius: '5px', marginBottom: '10px' }}>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ background: '#25377A' }}>
                  <TableCell sx={{ maxWidth: '50px', color: 'white' }}> No</TableCell>
                  <TableCell sx={{ color: 'white' }}>Date</TableCell>
                  <TableCell sx={{ color: 'white' }}>Amount</TableCell>
                  <TableCell sx={{ color: 'white' }}>Payment Status</TableCell>
                  <TableCell sx={{ color: 'white' }}>Payment Date</TableCell>
                  <TableCell sx={{ color: 'white' }}>Invoice</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instalments?.map((item, key) => (
                  <TableRow id={`id_${item._id}`} key={key} sx={{ background: item.isDue ? 'red' : '#E3E9FF' }}>
                    <TableCell sx={{ maxWidth: '50px', color: item.isDue ? 'white' : 'black' }}>{key + 1}</TableCell>
                    <TableCell sx={{ color: item.isDue ? 'white' : 'black' }}>
                      {moment(item?.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                      {/* {item._id} */}
                    </TableCell>
                    <TableCell sx={{ color: item.isDue ? 'white' : 'black' }}>{`₹${fNumber(item?.amount)}`}</TableCell>
                    <TableCell className={item.isDue && 'paymentStatus'} sx={{ color: item.isDue ? 'white' : 'black' }}>
                      {item?.status === 'PENDING' ? (
                        <FormControl>
                          <TextField
                            select
                            sx={{ select: { color: 'red' } }}
                            // classes={classes}
                            // inputProps={{ style: { color: 'red' } }}

                            size="small"
                            fullWidth
                            value={item?.status}
                            onChange={(e) => handleInstalmentStatus(e, item)}
                            variant="standard"
                          >
                            <MenuItem value="PENDING">PENDING</MenuItem>
                            <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                          </TextField>
                        </FormControl>
                      ) : (
                        <>{item?.status}</>
                      )}
                    </TableCell>
                    <TableCell sx={{ color: item.isDue ? 'white' : 'black' }}>
                      {item.paymentDate ? moment(item?.paymentDate).format('DD-MM-YYYY') : 'NA'}
                    </TableCell>
                    <TableCell sx={{ color: item.isDue ? 'white' : 'black' }}>
                      <Link
                        to={
                          item?.invoiceNumber
                            ? `/dashboard/payment/${item.invoiceNumber}?instalment=${item._id}&user=${investmentData.userId}`
                            : ''
                        }
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {item?.invoiceNumber || 'NA'}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {editMode && plan === 'RPC' && (
          <InstalmentEditTableRPC
            instalments={instalments}
            investmentData={investmentData}
            getInvestment={getInvestment}
            setEditMode={setEditMode}
          />
        )}
        {editMode && plan === 'RFC' && (
          <InstalmentEditTableRFC
            instalments={instalments}
            investmentData={investmentData}
            getInvestment={getInvestment}
            setEditMode={setEditMode}
          />
        )}
      </Scrollbar>
    </>
  );
}
