// @mui
import PropTypes from 'prop-types';
import { Box, Card, Paper, Typography, CardHeader, CardContent, Stack } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

MemberCount.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function MemberCount({ title, subheader, list, ...other }) {
  return (
    <Card sx={{ height: '100%' }}>
      <Stack direction="row" justifyContent="center" marginY={0.5}>
        <Typography variant="h5">MEMBERS</Typography>
      </Stack>
      <Box sx={{ paddingX: 1 }}>
        <Box
          sx={{
            display: 'grid',
            rowGap: 1.5,
            columnGap: 1.25,
            height: '100%',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography color={'#E2AF00'} variant="h4">
              {list?.rfc}
            </Typography>
            <Typography color={'#E2AF00'} variant="body2">
              RFC MEMBERS
            </Typography>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography color={'#00719F'} variant="h4">
              {list?.rpc}
            </Typography>
            <Typography color={'#00719F'} variant="body2">
              RPC MEMBERS
            </Typography>
          </Paper>
          {/* <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography variant="h4">0</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              UPCOMING CLUB
            </Typography>
          </Paper> */}
          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: '#6F8796',
              }}
            >
              {list?.total}
            </Typography>
            <Typography variant="body2" sx={{ color: '#6F8796' }}>
              TOTAL MEMBERS
            </Typography>
          </Paper>
        </Box>

        <Stack direction="row" justifyContent="center" marginY={0.5}>
          <Typography variant="h5">UNITS</Typography>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            rowGap: 1.5,
            columnGap: 1.25,
            height: '100%',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
         
          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography color={'#E2AF00'} variant="h4">
              {list?.rfcUnits}
            </Typography>
            <Typography color={'#E2AF00'} variant="body2">
              RFC UNITS
            </Typography>
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography color={'#00719F'} variant="h4">
              {list.rpcUnits}
            </Typography>
            <Typography color={'#00719F'} variant="body2">
              RPC UNITS
            </Typography>
          </Paper>


          {/* <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography color={'#000'} variant="h4">
              0
            </Typography>
      
          </Paper> */}


          <Paper
            variant="outlined"
            sx={{
              py: 2,
              px: 1,
              textAlign: 'center',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            }}
          >
            <Typography variant="h4" sx={{color:"#6F8796"}}>{list?.rfcUnits + list?.rpcUnits}</Typography>
            <Typography sx={{ color: '#6F8796' }} variant="body2">
              TOTAL UNITS
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Card>
  );
}
