import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import { Refresh } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../filters/PrivilegeCard';

// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
// import Popup from '../components/popups/privilegeCardStatusChange';

export default function Users() {
  const [privilegeCardData, setPrivilegeCardData] = useState();
  const [pages, setPages] = useState(0);
  const [privilegeCardCount, setPrivilegeCardCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getPrivilegeCards(temp);
  };

  const getPrivilegeCards = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/privilege-card/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setPrivilegeCardCount(res.data.data.maxRecords);
          setPrivilegeCardData(res.data.data.privilegeCards);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    if (input > Math.ceil(privilegeCardCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getPrivilegeCards(temp);
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setOptions(temp);
    setPages(0);
    getPrivilegeCards(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.clubId.length) {
      temp.clubId = filter.clubId;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname.trim();
    }
    if (filter.phone.length) {
      temp.phone = filter.phone;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.privilegeCardNumber.length) {
      temp.privilegeCardNumber = filter.privilegeCardNumber;
    }

    setOptions(temp);
    setPages(0);
    getPrivilegeCards(temp);
  };
  const handleRefresh = () => {
    const temp = { page: 0, size };
    setOptions(temp);
    setPages(0);
    getPrivilegeCards(temp);
  };
  useEffect(() => {
    getPrivilegeCards(options);
  }, []);
  return (
    <>
      <Page title="Privilege Card">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Users
              </Typography>
              {/* <Button variant="contained">New PrivilegeCard</Button> */}
            </Stack>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <TextField
                    size="small"
                    style={{ maxWidth: '100px', maxHeight: '35px' }}
                    type="number"
                    component="div"
                    label="Go to"
                    value={input}
                    onInput={(e) => setInput(e.target.value)}
                  />
                  <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                    Go
                  </Button>
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Refresh />}
                    onClick={() => handleRefresh()}
                    style={{ maxHeight: '35px' }}
                  >
                    Refresh
                  </Button>
                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <PrivilegeCardListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Card Number</TableCell>
                        <TableCell>Fullname</TableCell>
                        {/* <TableCell>Email</TableCell> */}
                        <TableCell>Phone</TableCell>
                        <TableCell>Date Enabled</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {privilegeCardData?.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                            <Stack direction="column" spacing={0.5} width="80px">
                              <CopyToClipboard text={item.userId} onCopy={() => toast.info('User id copied')}>
                                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                  <Iconify
                                    icon="lucide:clipboard-copy"
                                    style={{ height: '18px', width: '18px', color: '#09ADBD' }}
                                  />
                                  <Typography sx={{ color: '#09ADBD' }} variant="p">
                                    Copy ID
                                  </Typography>
                                </Stack>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell>{item.privilegeCardNumber}</TableCell>
                          <TableCell style={{ minWidth: '130px', wordBreak: 'break-all' }}>
                            {item?.fullname?.toUpperCase()}
                          </TableCell>
                          {/* <TableCell style={{ minWidth: '130px', wordBreak: 'break-all' }}>{item.email}</TableCell> */}
                          <TableCell>{item.phone}</TableCell>
                          <TableCell>{Moment(item.createdAt).format('MMMM DD, YYYY')}</TableCell>
                          <TableCell>
                            <Box>
                              <Link to={`/dashboard/user/${item.userId}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowsPerPageChange={handleSizeChange}
                component={'div'}
                showFirstButton
                showLastButton
                count={privilegeCardCount}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Card>
          </>
        )}
      </Page>
    </>
  );
}
