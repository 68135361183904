import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import 'react-toastify/dist/ReactToastify.css';

// components
import Page from '../components/Page';
import Tickets from '../components/customerSupportLists/Tickets';

export default function PrivilegeCard() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Customer Support">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Customer Support
            </Typography>
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="PENDING" value="1" />
                    <Tab label="CLOSED" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Tickets status={'PENDING'} />
                </TabPanel>
                <TabPanel value="2">
                  <Tickets status={'CLOSED'} />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
