import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Iconify from '../Iconify';
import { fNumber } from '../../utils/formatNumber';

export default function BasicInfo({ investmentData, getInvestment }) {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const goto = searchParams.get('goto');
  const navigate = useNavigate();

  const [basicInfoEdit, setBasicInfoEdit] = useState(false);

  useEffect(() => {
    const element = document.getElementById(`id_${goto}`);
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          boxShadow: '#6E8AEE 0px 1px 4px',
          borderRadius: '10px',
          padding: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" id={`id_${investmentData._id}`} justifyContent={'space-between'} marginBottom={-2}>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Investment Details</Typography>
            </Stack>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={4}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                padding={2}
              >
                <Iconify
                  icon="fluent:document-page-number-24-filled"
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              </Stack>

              <Stack direction="column" sx={{ padding: 1 }}>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Plan</Typography>
                <Typography style={{ wordBreak: 'break-all' }}>{investmentData?.plan?.replaceAll('_', ' ')}</Typography>
              </Stack>
            </Stack>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                padding={2}
              >
                <Iconify
                  icon="fluent:document-page-number-24-filled"
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              </Stack>

              <Stack direction="column" sx={{ padding: 1 }}>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Contribution Amount</Typography>
                <Typography style={{ wordBreak: 'break-all' }}>{`₹${fNumber(
                  investmentData?.contributionAmount
                )}`}</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                padding={2}
              >
                <Iconify
                  icon="fluent:document-page-number-24-filled"
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              </Stack>

              <Stack direction="column" sx={{ padding: 1 }}>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Method</Typography>
                <Typography style={{ wordBreak: 'break-all' }}>{investmentData?.paymentMethod}</Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Stack
              alignItems="center"
              spacing={1}
              direction="row"
              sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                padding={2}
              >
                <Iconify
                  icon="fluent:document-page-number-24-filled"
                  style={{ height: '30px', width: '30px', color: 'white' }}
                />
              </Stack>

              <Stack direction="column" sx={{ padding: 1 }}>
                <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Payment Mode</Typography>
                <Typography style={{ wordBreak: 'break-all' }}>{investmentData?.paymentMode}</Typography>
              </Stack>
            </Stack>
          </Grid>

          {investmentData?.metadata?.rfc && (
            <Grid item xs={12}>
              <Stack direction="row" id={`id_${investmentData._id}`} justifyContent={'space-between'} marginBottom={-2}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Investment Breakdown</Typography>
              </Stack>
            </Grid>
          )}

          {investmentData?.metadata?.rfc?.baseAmount && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Stack
                alignItems="center"
                spacing={1}
                direction="row"
                sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                  padding={2}
                >
                  <Iconify icon="bi:1-circle-fill" style={{ height: '25px', width: '25px', color: 'white' }} />
                </Stack>

                <Stack direction="column" sx={{ padding: 1 }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Base Amount</Typography>
                  <Typography style={{ wordBreak: 'break-all' }}>
                    {investmentData?.metadata?.rfc?.baseAmount}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}

          {investmentData?.metadata?.rfc?.registrationFee && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Stack
                alignItems="center"
                spacing={1}
                direction="row"
                sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                  padding={2}
                >
                  <Iconify icon="bi:2-circle-fill" style={{ height: '25px', width: '25px', color: 'white' }} />
                </Stack>

                <Stack direction="column" sx={{ padding: 1 }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Registration Fee</Typography>
                  <Typography style={{ wordBreak: 'break-all' }}>
                    {investmentData?.metadata?.rfc?.registrationFee}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}

          {investmentData?.metadata?.rfc?.privilegeCardFee && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Stack
                alignItems="center"
                spacing={1}
                direction="row"
                sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                  padding={2}
                >
                  <Iconify icon="bi:3-circle-fill" style={{ height: '25px', width: '25px', color: 'white' }} />
                </Stack>

                <Stack direction="column" sx={{ padding: 1 }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Privilege Card Fee</Typography>
                  <Typography style={{ wordBreak: 'break-all' }}>
                    {investmentData?.metadata?.rfc?.privilegeCardFee}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}

          {!investmentData?.metadata?.rfc?.privilegeCardFee && investmentData?.plan ==="RILL_FOUNDERS_CLUB" && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Stack
                alignItems="center"
                spacing={1}
                direction="row"
                sx={{ height: '100%', background: 'white', borderRadius: '5px', border: '1px solid #C4D0FD' , cursor: 'pointer' }}
                onClick={() => { navigate(`/dashboard/payment/privilege/${investmentData?.privilegeCard?.invoiceNumber}/?privilegeCard=${investmentData?.privilegeCard?._id}&user=${id}`) }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '100%', background: '#09ADBD', borderRadius: '5px 0px 0px 5px' }}
                  padding={2}
                >
                  <Iconify icon="mdi:card-account-details" style={{ height: '25px', width: '25px', color: 'white' }} />
                </Stack>

                <Stack direction="column" sx={{ padding: 1 }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Privilege Card</Typography>
                  <Typography style={{ wordBreak: 'break-all' }}>
                    {investmentData?.privilegeCard?.invoiceNumber}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}
