import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Box } from '@mui/system';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../filters/KycFilter';

// components
import Page from '../Page';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';

export default function Success() {
  const navigate = useNavigate();
  const [kycData, setKycData] = useState();
  const [pages, setPages] = useState(0);
  const [kycCount, setKycCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getKycs(temp);
  };

  const getKycs = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/kyc/completed`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setKycCount(res.data.data.maxRecords);
          setKycData(res.data.data.kycs);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSizeChange = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setPages(0);
    setOptions(temp);
    getKycs(temp);
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(kycCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getKycs(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.clubId.length) {
      temp.clubId = filter.clubId;
    }
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.phone.length) {
      temp.phone = filter.phone;
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    setOptions(temp);
    setPages(0);
    getKycs(temp);
  };
  const handleRefresh = () => {
    const temp = { page: 0, size };
    setOptions(temp);
    setPages(0);
    getKycs(temp);
  };
  useEffect(() => {
    getKycs(options);
  }, []);
  return (
    <>
      <Page title="KYC">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <TextField
                    size="small"
                    style={{ maxWidth: '100px', maxHeight: '35px' }}
                    type="number"
                    component="div"
                    label="Go to"
                    value={input}
                    onInput={(e) => setInput(e.target.value)}
                  />
                  <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                    Go
                  </Button>
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Refresh />}
                    onClick={() => handleRefresh()}
                    style={{ maxHeight: '35px' }}
                  >
                    Refresh
                  </Button>
                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <KycListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Fullname</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>PAN Status</TableCell>
                        <TableCell>Aadhaar Status</TableCell>
                        <TableCell>Bank Account Status</TableCell>
                        <TableCell>Passport Status</TableCell>
                        <TableCell>Kyc status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {kycData?.map((item, id) => (
                        <TableRow key={id}>
                          <TableCell style={{ width: '40px' }}>{pages * size + (id + 1)}</TableCell>
                          <TableCell style={{ cursor: 'pointer', width: '75px' }}>
                            <Stack direction="row" width="80px">
                              <Tooltip title="View User">
                                <IconButton onClick={() => navigate(`/dashboard/user/${item.userId}`)}>
                                  <Iconify icon="mdi:user" style={{ height: '22px', width: '22px', color: 'green' }} />
                                </IconButton>
                              </Tooltip>

                              <CopyToClipboard text={item.userId} onCopy={() => toast.info('User id copied')}>
                                <Tooltip title="Copy User ID">
                                  <IconButton>
                                    <Iconify
                                      icon="lucide:clipboard-copy"
                                      style={{ height: '22px', width: '22px', color: '#09ADBD' }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ width: '150px', wordBreak: 'break-all' }}>
                            {item?.fullname?.toUpperCase()}
                          </TableCell>
                          <TableCell>{item?.phone}</TableCell>
                          <TableCell>{item?.panStatus?.replaceAll('_', ' ')}</TableCell>
                          <TableCell>{item?.aadhaarStatus?.replaceAll('_', ' ')}</TableCell>
                          <TableCell>{item?.bankStatus?.replaceAll('_', ' ')}</TableCell>
                          <TableCell>
                            {(item?.passportStatus && item.passportStatus?.replaceAll('_', ' ')) || 'NA'}
                          </TableCell>
                          <TableCell>{item.kycStatus ? 'COMPLETED' : 'PENDING'} </TableCell>
                          <TableCell>
                            <Box>
                              <Link to={`/dashboard/user/${item.userId}?section=kyc`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowsPerPageChange={handleSizeChange}
                component={'div'}
                count={kycCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Card>
          </>
        )}
      </Page>
    </>
  );
}
