import * as Yup from 'yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// form
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root ': {
    borderRadius: 2,
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
  },
});

const CssButton = styled(LoadingButton)({
  '&.MuiButtonBase-root': {
    borderRadius: '3px',
    boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Roboto',
    backgroundColor: '#09ADBD',
    '&:hover': {
      backgroundColor: '#03646e',
      boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.15)',
    },
  },
});

export default function NewPasswordForm({ token }) {
  const navigate = useNavigate();
  const uninterceptedAxiosInstance = axios.create();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  // const

  const passwordSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      token,
      password: '',
      confirmPassword: '',
    },
    validationSchema: passwordSchema,
    onSubmit: async () => {
      if (values.password !== values.confirmPassword) {
        formik.setFieldError('confirmPassword', 'Password and Confirm Password must be same');
        return;
      }
      try {
        const res = await uninterceptedAxiosInstance.post(
          `${process.env.REACT_APP_API_URL}/auth/admin/reset-password/verify`,
          values
        );
        if (!res.data.isError) {
          console.log(res.data.message);
          toast(res.data.message);
          navigate('/login', { replace: true });
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleConfirmShowPassword = () => {
    setConfirmShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={4}>
          <CssTextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <CssTextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleConfirmShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Stack>

        <CssButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </CssButton>
      </Form>
    </FormikProvider>
  );
}
