import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter1 = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Investments</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                investmentDatePlan: '',
                investmentEndDate: '',
                investmentStartDate: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column">
                    <Stack direction="row" spacing={1}>
                      <TextField
                        select
                        style={{ paddingBottom: '10px' }}
                        fullWidth
                        id="investmentDatePlan"
                        name="investmentDatePlan"
                        label="Plan"
                        value={values.investmentDatePlan}
                        onChange={handleChange}
                      >
                        <MenuItem value="RFC">RFC</MenuItem>
                        <MenuItem value="RPC">RPC</MenuItem>
                        <MenuItem value="ANY">ANY</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                      <TextField
                        type="date"
                        name="investmentStartDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={values.investmentStartDate}
                        onChange={handleChange}
                      />
                      <p style={{ paddingTop: '15px' }}>to</p>
                      <TextField
                        type="date"
                        name="investmentEndDate"
                        fullWidth
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        value={values.investmentEndDate}
                        onChange={handleChange}
                      />
                    </Stack>

                    <Button
                      variant="contained"
                      disabled={!values.investmentEndDate && !values.investmentStartDate}
                      type="submit"
                      style={{ marginTop: '10px' }}
                    >
                      Apply
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter1;
