import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import ViewUser from './pages/ViewUser';
import Kycs from './pages/Kycs';
import ViewKyc from './pages/ViewKyc';
import Payments from './pages/Payments';
import ViewPayment from './pages/ViewPayment';
import PrivilegeCards from './pages/PrivilegeCards';
import ViewPrivilegeCardPayment from './pages/ViewPrivilegeCardPayment';
import CustomerSupport from './pages/CustomerSupport';
import Profile from './pages/Profile';
import Admins from './pages/Admins';
import ViewAdmin from './pages/ViewAdmin';
import ResetPassword from './pages/ResetPassword';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'user/:id', element: <ViewUser /> },
        { path: 'kycs', element: <Kycs /> },
        { path: 'kyc/:id', element: <ViewKyc /> },
        { path: 'payments', element: <Payments /> },
        { path: 'payment/:id', element: <ViewPayment /> },
        { path: 'cards', element: <PrivilegeCards /> },
        { path: 'payment/privilege/:id', element: <ViewPrivilegeCardPayment /> },
        { path: 'support', element: <CustomerSupport /> },
        { path: 'profile', element: <Profile /> },
        { path: 'account', element: <Admins /> },
        { path: 'account/view/:id', element: <ViewAdmin /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'login', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'reset-pass', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
